<template>
  <div>
    <MoleculeGrid
      :title="$t('client.list')"
      :loading="state.loading"
      :columns="columns"
      :rows="clients"
      :pagination="true"
      :search="['fullname', 'phone', 'email']"
      :filters="filters"
    >
      <template #actions>
        <AtomButton
          color="primary"
          class="d-none d-sm-inline-block"
          @click.prevent="createClientHandler"
          :disabled="state.loading || state.actionLoading"
        >
          <plus-icon />{{ $t('client.create') }}
        </AtomButton>
        <AtomButton
          color="primary"
          class="d-sm-none"
          :aria-label="$t('client.create')"
          @click.prevent="createClientHandler"
          :disabled="state.loading || state.actionLoading"
        >
          <plus-icon />
        </AtomButton>
        <MoleculeModal
          :title="modal.title"
          :show="modal.show"
          @close="modal.show = false"
        >
          <template slot="body">
            <MoleculeForm
              :validator="$v"
              :loading="state.actionLoading"
              :disabled="modal.action === 'edit' || state.actionLoading"
            >
              <div class="row">
                <div class="col-lg-6">
                  <AtomInput
                    class="mb-3"
                    v-model.trim="client.name"
                    label="name"
                  />
                </div>
                <div class="col-lg-6">
                  <AtomInput
                    class="mb-3"
                    v-model.trim="client.surname"
                    label="surname"
                  />
                </div>
                <div class="col-lg-12">
                  <AtomInput
                    class="mb-3"
                    v-model.trim="client.patronymic"
                    label="patronymic"
                  />
                </div>
                <div class="col-lg-12">
                  <AtomInput
                    class="mb-3"
                    v-model.trim="client.phone"
                    label="phone"
                    type="phone"
                  />
                </div>
                <div class="col-lg-12">
                  <AtomSelect
                    v-model.number="client.projectId"
                    :options="projects"
                    label="project"
                    value-label="name"
                    :reduce="project => project.id"
                    :searchable="true"
                    class="mb-3"
                  />
                </div>
                <div class="col-lg-12">
                  <AtomInput
                    v-model.trim="client.email"
                    label="email"
                  />
                </div>
              </div>
            </MoleculeForm>
            <AtomFileInput
              v-if="modal.action === 'edit'"
              v-model="client.registrationRecord"
              label="file"
              accept=".pdf"
              :max-size="4000"
              :disabled="state.actionLoading"
              class="mt-3"
            />
          </template>
          <template slot="footer">
            <AtomButton
              class="me-auto"
              @click="modal.show = false"
            >
              {{ $t('action.close') }}
            </AtomButton>
            <AtomButton
              color="primary"
              class="ms-auto"
              :loading="state.actionLoading"
              :disabled="$v.$anyError || state.actionLoading"
              @click="submitHandler(modal.action)"
            >
              <plus-icon v-if="modal.action !== 'edit'" />{{ modal.submit }}
            </AtomButton>
          </template>
        </MoleculeModal>
      </template>
      <template #empty>
        <AtomButton
          color="primary"
          class="d-none d-sm-inline-block"
          @click.prevent="createClientHandler"
          :disabled="state.loading || state.actionLoading"
        >
          <plus-icon />{{ $t('client.can_create') }}
        </AtomButton>
      </template>
      <template #fullname="{ data }">
        <MoleculeAvatarDetails
          :title="data.fullname"
          truncate="200px"
        >
          <template v-if="data.email" #subtitle>
            <a :href="'mailto:' + data.email" class="text-reset">{{ data.email }}</a>
          </template>
        </MoleculeAvatarDetails>
      </template>
      <template #broker="{ data }">
        <AtomButton
          class="w-100 justify-content-start text-start"
          :disabled="state.actionLoading"
          @click.prevent="showBroker(data.createdUser)"
        >
          <div class="text-muted">
            <small
              v-if="data.createdUser && data.createdUser.fullname"
              class="d-block fs-h5"
            >
              {{ data.createdUser.fullname }}
            </small>
            <small
              v-if="data.createdUser && data.createdUser.company && data.createdUser.company.name"
              class="d-block text-nowrap fs-h6"
            >
              {{ data.createdUser.company.name }}
            </small>
          </div>
        </AtomButton>
      </template>
      <template #phone="{ data }">
        <AtomPhone>{{ data.phone }}</AtomPhone>
      </template>
      <template #status="{ data }">
        <AtomStatus :color="{
          green: data.status === 'active',
          warning: data.status === 'warning',
          danger: data.status === 'expires' || data.status === 'has-error',
        }">{{ $t(`status.${data.status}`) }}</AtomStatus>
      </template>
      <template #registrationRecord="{ data }">
        <a v-if="data.registrationRecord" :href="data.registrationRecord" target="_blank">{{ $t('action.open') }} <external-link-icon /></a>
      </template>
      <template #createdAt="{ data }">
        <time
          v-if="data.createdAt"
          class="text-nowrap text-muted"
          :datetime="data.createdAt.toISOString()"
          :title="$options.filters.date(data.createdAt, 'datetime')"
        >
          {{ data.createdAt | date }}
        </time>
      </template>
      <template #expireAt="{ data }">
        <time
          v-if="data.expireAt"
          class="text-nowrap text-muted"
          :datetime="data.expireAt.toISOString()"
          :title="$options.filters.date(data.expireAt, 'datetime')"
        >
          {{ data.expireAt | date }}
        </time>
      </template>
      <template #action="{ data }">
        <div class="text-end">
          <div class="btn-list flex-nowrap">
            <AtomButton
              v-if="isStaff && data.active"
              :disabled="state.actionLoading"
              @click.prevent="editClientHandler(data)"
            >
              <edit-icon />
            </AtomButton>
            <AtomButton
              v-if="
                accessLevel >= 2
                && data.extendRequested
                && !data.registrationRecord
                && data.active
              "
              color="success"
              :loading="state.actionLoading"
              @click.prevent="extendClientHandler(data)"
            >
              {{ $t('client.extend') }}
            </AtomButton>
            <AtomButton
              v-else-if="
                data.createdUser.id === info.uid
                && data.extendedCount < 2
                && !data.registrationRecord
                && data.active
              "
              :disabled="state.actionLoading || data.extendRequested"
              @click.prevent="extendClientRequestHandler(data)"
            >
              <template v-if="data.extendRequested">
                {{ $t('client.extend-requested') }}
              </template>
              <template v-else>
                {{ $t('client.extend') }}
              </template>
            </AtomButton>
          </div>
        </div>
      </template>
    </MoleculeGrid>
    <MoleculeModal
      v-if="isStaff"
      :show="broker.modalShow"
      @close="broker.modalShow = false; broker.data = {}"
    >
      <template
        v-if="broker.data"
        slot="body"
      >
        <div class="divide-y-2">
          <div
            v-if="broker.data.fullname"
            class="d-flex justify-content-between"
          >
            <MoleculeAvatarDetails
              v-if="broker.data.fullname"
              :title="broker.data.fullname"
            />
          </div>
          <div
            v-if="broker.data.phone"
            class="d-flex justify-content-between"
          >
            <span>{{ $t('phone.label') }}:</span>
            <span><AtomPhone>{{ broker.data.phone }}</AtomPhone></span>
          </div>
          <div
            v-if="broker.data.email"
            class="d-flex justify-content-between"
          >
            <span>{{ $t('email.label') }}:</span>
            <span><a :href="'mailto:' + broker.data.email">{{ broker.data.email }}</a></span>
          </div>
          <div
            v-if="broker.data.company && broker.data.company.name"
            class="d-flex justify-content-between"
          >
            <span>{{ $t('company.label') }}:</span>
            <span>{{ broker.data.company.name }}</span>
          </div>
        </div>
      </template>
    </MoleculeModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { email, required, minLength } from 'vuelidate/lib/validators'

import MoleculeAvatarDetails from '@/components/MoleculeAvatarDetails'
import MoleculeGrid from '@/components/MoleculeGrid'
import MoleculeModal from '@/components/MoleculeModal'
import MoleculeForm from '@/components/MoleculeForm'
import AtomInput from '@/components/AtomInput'
import AtomFileInput from '@/components/AtomFileInput'
import AtomSelect from '@/components/AtomSelect'
import AtomButton from '@/components/AtomButton'
import AtomPhone from '@/components/AtomPhone'
import AtomStatus from '@/components/AtomStatus'

export default {
  name: 'OrganismClientsGrid',

  components: {
    MoleculeAvatarDetails,
    MoleculeGrid,
    MoleculeModal,
    MoleculeForm,
    AtomInput,
    AtomFileInput,
    AtomSelect,
    AtomButton,
    AtomPhone,
    AtomStatus,
  },

  data: () => ({
    client: {
      id: '',
      surname: '',
      name: '',
      patronymic: '',
      phone: '',
      projectId: '',
      email: '',
      registrationRecord: '',
    },

    modal: {
      title: '',
      action: '',
      submit: '',
      show: false,
    },

    broker: {
      modalShow: false,
      data: {},
    },

    state: {
      loading: true,
      actionLoading: false,
    },
  }),

  validations: {
    client: {
      name: { required, minLength: minLength(3) },
      patronymic: { minLength: minLength(5) },
      phone: { required, minLength: minLength(19) },
      projectId: { required },
      email: { email },
    },
  },

  computed: {
    ...mapGetters(['clients', 'projects', 'isStaff', 'users', 'info', 'accessLevel']),

    columns () {
      const statusWeights = ['active', 'warning', 'expires', 'non-unique', 'inactive'].reverse()
      const getWeight = item => statusWeights.findIndex(weightedItem => item.toLowerCase().indexOf(weightedItem.toLowerCase()) > -1)

      const columns = [
        { key: 'idx', name: '№', width: 5, sortable: true },
        { key: 'fullname', name: this.$t('client.info'), width: 20 },
        { key: 'phone', name: this.$t('phone.label'), width: 20, sortable: true },
        { key: 'status', name: this.$t('status.label'), width: 10, sortable: true, sort: (a, b) => getWeight(b.status) - getWeight(a.status) },
        { key: 'registrationRecord', name: this.$t('client.registration-record'), width: 10, sortable: true },
        { key: 'createdAt', name: this.$t('date.created.label'), width: 15, sortable: true },
        { key: 'expireAt', name: this.$t('date.expire.label'), width: 15, sortable: true },
      ]

      if (this.isStaff) {
        columns.splice(2, 0, { key: 'broker', name: this.$t('broker.label'), width: 15, sortable: true, sort: (a, b) => a.createdUser.fullname.localeCompare(b.createdUser.fullname) })
      }

      let actionColumn = {
        key: 'action',
        width: 15,
      }

      if (this.isStaff && this.accessLevel >= 2) {
        actionColumn = {
          ...actionColumn,
          name: this.$t('client.extend-requested'),
          sortable: true,
          sort: (a, b) => {
            return Object.prototype.hasOwnProperty.call(a, 'extendRequested') && a.extendRequested && !a.registrationRecord && a.active ? -1 : Object.prototype.hasOwnProperty.call(b, 'extendRequested') && b.extendRequested && !b.registrationRecord && b.active ? 1 : 0
          },
        }
      }

      columns.push(actionColumn)

      return columns
    },

    filters () {
      const filters = [{
        key: 'status',
        label: row => this.$t(`status.${row.status}`),
        placeholder: this.$t('status.label'),
      }]

      if (this.isStaff) {
        filters.unshift({
          key: 'createdBy',
          label: 'createdUser.fullname',
          placeholder: this.$t('broker.label'),
          class: 'col-12 col-md-3',
        })
        filters.push({
          key: 'createdAt',
          type: 'date',
          placeholder: this.$t('date.created.label'),
          class: 'col-12 col-md-3',
        })
      }

      return filters
    },
  },

  async mounted () {
    try {
      await this.fetchClients()
      await this.fetchProjects()
    } catch {} finally {
      this.state.loading = false
    }
  },

  methods: {
    ...mapActions([
      'fetchClients',
      'fetchProjects',
      'fetchUsers',
      'createClient',
      'editClient',
      'extendClientRequest',
      'extendClient',
      'getUser',
    ]),

    async submitHandler (action) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      try {
        this.state.actionLoading = true
        if (action === 'edit') {
          await this.editClient(this.client)
        } else {
          await this.createClient(this.client)
        }
      } catch {} finally {
        this.state.actionLoading = false
        this.modal.show = false
        this.prepareData()
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t(`client.success.${action}`),
          type: 'success',
        })
      }
    },

    createClientHandler () {
      this.prepareData()
      this.modal.title = this.$t('client.create')
      this.modal.submit = this.$t('action.create')
      this.modal.action = 'create'
      this.modal.show = true
    },

    editClientHandler (client) {
      this.prepareData(client)
      this.modal.title = this.$t('client.edit')
      this.modal.submit = this.$t('action.save')
      this.modal.action = 'edit'
      this.modal.show = true
    },

    async extendClientRequestHandler (data) {
      if (!data.id || data.extendedCount >= 2 || data.registrationRecord) return

      try {
        this.state.actionLoading = true
        const client = await this.extendClientRequest(data.id)
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('client.success.extend-requested', { name: client.fullname }),
          type: 'success',
        })
      } catch {} finally {
        this.state.actionLoading = false
      }
    },

    async extendClientHandler (data) {
      if (!data.id || data.extendedCount >= 2) return

      try {
        this.state.actionLoading = true
        const client = await this.extendClient(data.id)
        this.$notify({
          title: this.$t('action.success'),
          text: this.$t('client.success.extended', {
            name: client.fullname,
            date: this.$options.filters.date(client.expireAt, 'date'),
          }),
          type: 'success',
        })
      } catch {} finally {
        this.state.actionLoading = false
      }
    },

    prepareData (client) {
      for (const [key] of Object.entries(this.client)) {
        this.client[key] = client?.[key] || ''
      }
    },

    async showBroker (user) {
      this.state.actionLoading = true
      try {
        this.broker.data = user
        this.broker.modalShow = true
      } catch {} finally {
        this.state.actionLoading = false
      }
    },
  },
}
</script>
