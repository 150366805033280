<template>
  <div class="page">
    <MoleculeLoading v-if="loading" />
    <template v-else>
      <OrganismHeader />
      <slot>
        <router-view />
      </slot>
      <OrganismFooter />
    </template>
    <Confirm />
  </div>
</template>

<script>
import OrganismHeader from '@/components/OrganismHeader'
import OrganismFooter from '@/components/OrganismFooter'
import MoleculeLoading from '@/components/MoleculeLoading'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TemplateMain',

  components: {
    OrganismHeader,
    OrganismFooter,
    MoleculeLoading,
  },

  data: () => ({
    loading: true,
  }),

  computed: mapGetters(['info']),

  async mounted () {
    try {
      if (!Object.keys(this.info).length) {
        await this.fetchInfo()
      }
    } catch {} finally {
      this.loading = false
    }
  },

  methods: mapActions(['fetchInfo']),
}
</script>
