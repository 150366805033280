import { mapGetters, mapActions } from 'vuex'

export default {
  computed: mapGetters(['theme', 'choosedTheme']),

  watch: {
    theme: {
      handler (theme) {
        if (theme === 'dark') {
          document.body.classList.remove('theme-light')
          document.body.classList.add('theme-dark')
        } else {
          document.body.classList.remove('theme-dark')
          document.body.classList.add('theme-light')
        }
      },
      immediate: true,
    },
  },

  mounted () {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => !this.choosedTheme ? this.changeTheme({ value: event.matches ? 'dark' : 'light', system: true }) : '')
  },

  methods: mapActions(['changeTheme']),
}
