<template>
  <span
    class="status"
    :class="classes"
  >
    <span
      v-if="dot"
      class="status-dot"
    />
    <slot />
  </span>
</template>

<script>
import { allColors } from '@/helpers'

export default {
  name: 'AtomStatus',

  props: {
    dot: {
      type: Boolean,
      default: true,
    },

    color: {
      type: [String, Array, Object],
      validator (value) {
        if (value instanceof Object) {
          for (const [key] of Object.entries(value)) {
            if (!allColors.includes(key)) {
              return false
            }
          }
        } else if (Array.isArray(value)) {
          value.forEach(cls => {
            if (!allColors.includes(cls)) {
              return false
            }
          })
        } else {
          return allColors.includes(value)
        }

        return true
      },
    },
  },

  computed: {
    classes () {
      let classes = this.color
      if (typeof classes === 'string') {
        classes = classes.replace(/\s+/g, ' ').trim().split(' ')
      }

      if (Array.isArray(classes)) {
        classes = classes.map(classname => 'status-' + classname)
      } else if (typeof classes === 'object' && classes !== null) {
        for (const [key, value] of Object.entries(classes)) {
          classes['status-' + key] = value
          delete classes[key]
        }
      }

      return classes
    },
  },
}
</script>
