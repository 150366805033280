<template>
  <div class="page page-center">
    <div
      class="py-4"
      :class="containerClass"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateEmpty',

  computed: {
    isAuth () {
      return [
        'Login',
        'Forgot password',
        'Reset password',
        '404',
      ].includes(this.$route.name)
    },

    containerClass () {
      return `container-${this.isAuth ? 'tight' : 'narrow'}`
    },
  },
}
</script>
