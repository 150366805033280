const getSystemTheme = () => window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

export default {
  state: {
    theme: localStorage.theme || getSystemTheme() || '',
    choosedTheme: localStorage.choosedTheme || '',
  },

  mutations: {
    setTheme (state, theme) {
      state.theme = theme
    },

    setChoosedTheme (state, theme) {
      state.choosedTheme = theme
    },

    clearTheme (state) {
      delete localStorage.theme
      delete localStorage.choosedTheme
      state.theme = ''
      state.choosedTheme = ''
    },
  },

  actions: {
    changeTheme ({ commit }, value) {
      let theme = value instanceof Object ? value.value || '' : value
      const system = value?.system || false

      if (!system) {
        localStorage.choosedTheme = theme
        commit('setChoosedTheme', theme)
      }

      if (!theme) {
        theme = getSystemTheme()
      }

      localStorage.theme = theme
      commit('setTheme', theme)
    },
  },

  getters: {
    theme: s => s.theme,
    choosedTheme: s => s.choosedTheme,
  },
}
