<template>
  <div
    class="dropdown"
    @mouseleave="mouseLeave"
    @mouseover="mouseOver"
    @mouseenter="mouseEnter"
    @click="toggleMenu"
  >
    <slot></slot>
    <transition :name="transition">
      <div
        v-show="value"
        class="dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
        @mouseleave="startTimer"
        @mouseenter="stopTimer"
        @click.stop
        ref="dropdown"
        data-bs-popper="none"
      >
        <slot name="dropdown"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AtomDropdown',

  props: {
    value: Boolean,
    hover: Boolean,

    disabled: {
      type: Boolean,
      default: false,
    },

    hoverTime: {
      type: Number,
      default: 100,
    },

    hoverTimeout: {
      type: Number,
      default: 500,
    },

    interactive: {
      type: Boolean,
      default: false,
    },

    transition: {
      type: String,
      default: '',
    },

    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    hovering: false,
    top: false,
  }),

  watch: {
    value (value) {
      if (value) {
        this.top = false
        this.$nextTick(() => {
          const rect = this.$refs.dropdown.getBoundingClientRect()
          const windowHeight = (window.innerHeight || document.documentElement.clientHeight)
          this.top = (rect.bottom > windowHeight) && (rect.top >= rect.height)
        })
      }
    },

    interactive: {
      handler (value) {
        if (typeof document === 'object') {
          value ? document.body.addEventListener('click', this.closeMenu) : document.body.removeEventListener('click', this.closeMenu)
        }
      },
      immediate: true,
    },
  },

  destroyed () {
    document.body.removeEventListener('click', this.closeMenu)
  },

  methods: {
    mouseEnter () {
      this.stopTimer()

      if (this.hover && this.hoverTime > 0 && !this.value) {
        this.hoverOpenTimer = setTimeout(() => {
          this.$emit('input', true)
          this.hovering = true
          setTimeout(() => {
            this.hovering = false
          }, this.hoverTimeout)
        }, this.hoverTime)
      }

      if (this.hover && !this.value && this.hoverTime === 0) {
        this.hovering = true
        setTimeout(() => {
          this.hovering = false
        }, this.hoverTimeout)
        this.$emit('input', true)
      }
    },

    mouseLeave () {
      if (!this.hoverTimer) {
        this.startTimer()
      }
      if (this.hoverTime > 0 && this.hover) {
        clearTimeout(this.hoverOpenTimer)
      }
    },

    mouseOver () {
      this.stopTimer()
    },

    closeMenu ($event) {
      if (!$event || !this.$el.contains($event.target)) {
        if (this.value && this.closeOnClickOutside) {
          this.$emit('input', false)
        }
      }
    },

    toggleMenu () {
      if (this.disabled || this.hovering || (this.value && this.hover)) return
      this.$emit('input', !this.value)
    },

    stopTimer () {
      clearTimeout(this.hoverTimer)
      this.hoverTimer = null
    },

    startTimer () {
      if (!this.interactive) {
        this.hoverTimer = setTimeout(this.closeMenu, this.hoverTimeout)
      }
    },
  },
}
</script>
