<template>
  <div>
    <AtomLabel
      v-if="label"
      :label="preparedLabel"
      :class="labelClasses"
    >
      <template
        v-if="!!$slots.labelDescription || isLink"
        slot="description"
      >
        <slot name="labelDescription">
          <a
            v-if="isLink"
            :href="value"
            target="_blank"
          >
            {{ $t('file.open') }} <external-link-icon />
          </a>
        </slot>
      </template>
    </AtomLabel>
    <div class="input-group input-group-flat">
      <div
        class="form-control cursor-pointer text-truncate"
        :class="[inputClasses, isDisabled ? 'disabled' : '']"
        @click.self="clickHandler"
      >
        {{ screen || preparedPlaceholder }}
      </div>
      <span
        v-if="isFilled && !isDisabled"
        class="input-group-text"
      >
        <span
          class="link-secondary cursor-pointer"
          :title="$t('file.remove')"
          @click.prevent="clearValue()"
        >
          <x-icon />
        </span>
      </span>
    </div>
    <input
      type="file"
      ref="input"
      class="d-none"
      :id="id"
      :disabled="isDisabled"
      :accept="accept"
      :multiple="multiple"
      @input="updateValue($event.target.files)"
    >
    <small
      v-if="preparedDescription || !!$slots.description"
      class="form-hint"
    >
      <slot name="description">{{ preparedDescription }}</slot>
    </small>
    <span
      v-if="error || errorMessage"
      class="invalid-feedback"
      v-text="error || errorMessage"
    />
  </div>
</template>

<script>
import AtomLabel from '@/components/AtomLabel'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

export default {
  name: 'AtomFileInput',

  components: {
    AtomLabel,
  },

  mixins: [FormMixin, InputMixin],

  data () {
    return {
      screen: '',
      initialValue: this.value,
      errorMessage: '',
    }
  },

  props: {
    accept: {
      type: String,
      default: 'image/*',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    maxSize: {
      type: Number,
      default: 2048,
    },

    prefill: String,

    value: {
      type: [String, File, FileList, null],
      default: '',
    },
  },

  computed: {
    isFilled () {
      return !!this.value
    },

    isLink () {
      return typeof this.value === 'string' ? this.value.startsWith('http') : false
    },

    preparedDescription () {
      const output = []

      if (this.maxSize) {
        output.push(`${this.$t('file.validate.max-size')}: ${this.maxSize / 1000} MB`)
      }

      if (this.accept) {
        const formats = this.accept.replace('application/', '.')
        output.push(`${this.$t('file.validate.format')}: ${formats}`)
      }

      return output.join(' - ') || this.description
    },
  },

  methods: {
    clickHandler () {
      this.$refs.input.click()
    },

    clearValue (message) {
      this.$refs.input.value = ''
      this.errorMessage = message || ''
      this.screen = this.preparedPlaceholder
      this.$emit('input', this.initialValue ? null : '')
    },

    updateValue (value) {
      this.errorMessage = ''
      let output

      if (value !== null && value.length) {
        const files = [...value]

        if (this.accept && !files.every(file => this.accept.replace(/\s/g, '').split(',').filter(accept => new RegExp(accept.replace('*', '.*')).test(file.type)).length > 0)) {
          this.clearValue(this.$t('file.error.wrong-format'))

          return
        }

        if (this.maxSize && files.reduce((prev, current) => prev + (current.size / 1000), 0) > this.maxSize) {
          this.clearValue(this.$t('file.error.max-size-exceed'))

          return
        }

        this.screen = files.map(file => file.name).join(', ')
      }

      if (value?.length === 1) {
        output = value[0]
      } else if (!value?.length) {
        output = ''
      }

      this.$emit('input', output)
    },
  },
}
</script>
