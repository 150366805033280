<template>
  <span
    :class="classes"
    role="status"
  />
</template>

<script>
import { allColors } from '@/helpers'

export default {
  name: 'AtomSpinner',

  props: {
    color: {
      type: String,
      validator: value => allColors.includes(value),
    },

    size: {
      type: String,
      validator: value => ['sm', 'xs'].includes(value),
    },

    variant: {
      type: String,
      default: 'border',
      validator: value => ['grow', 'border'].includes(value),
    },
  },

  computed: {
    classes () {
      const classes = {}

      classes[`spinner-${this.variant}`] = true

      if (this.size) {
        classes[`spinner-${this.variant}-${this.size}`] = true
      }

      if (this.color) {
        classes[`text-${this.color}`] = true
      }

      return classes
    },
  },
}
</script>

<style important scoped>
  .spinner-border-xs {
    width: 0.5rem;
    height: 0.5rem;
    border-width: 1.5px;
  }
</style>
