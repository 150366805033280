import { firebase, clientsCollection, functions, storage, usersCollection } from '@/firebase'
import { sub, isPast, isSameDay, differenceInCalendarDays, format } from 'date-fns'
import { getFullname, CodeError } from '@/helpers'
import Vue from 'vue'
import { inRange } from 'lodash'

class Client {
  constructor (client, id) {
    if (client) {
      Object.assign(this, client)

      this.fullname = getFullname(client)
      this.createdAt = client.createdAt ? client.createdAt.toDate() : new Date()
      if (client.expireAt) {
        this.expireAt = client.expireAt instanceof Date ? client.expireAt : client.expireAt.toDate()
      }

      this.extendedCount = client.extendedCount || 0

      if (!this.hasError) {
        if (!this.processing) {
          if (this.expireAt) {
            this.active = !isPast(this.expireAt)

            if (this.active) {
              const daysLeft = differenceInCalendarDays(this.expireAt, new Date())
              if (inRange(daysLeft, 4, 7)) {
                this.status = 'warning'
              } else if (inRange(daysLeft, 0, 4)) {
                this.status = 'expires'
              } else {
                this.status = 'active'
              }
            } else {
              this.status = 'inactive'
            }
          } else {
            this.active = false
            this.status = 'non-unique'
          }
        } else {
          this.active = false
          this.status = 'processing'
        }
      } else {
        this.active = false
        this.status = 'has-error'
      }
    }

    if (id) {
      this.id = id
    }
  }
}

export default {
  state: {
    clients: [],
  },

  mutations: {
    setClients (state, clients = []) {
      state.clients = clients
    },

    setClient (state, client) {
      const index = state.clients.findIndex(c => c.id === client.id)
      if (index !== -1) {
        Vue.set(state.clients, index, client)
      } else {
        state.clients.unshift(client)
      }
    },

    clearClients (state) {
      state.clients = []
    },
  },

  actions: {
    async createClient ({ dispatch, commit }, data) {
      try {
        const uid = await dispatch('getUid')
        const clientRef = await clientsCollection.doc()
        await clientRef.set({
          surname: data.surname,
          name: data.name,
          patronymic: data.patronymic,
          phone: data.phone,
          email: data.email,
          projectId: data.projectId,
          createdBy: uid,
          createdUser: usersCollection.doc(uid),
          processing: true,
        })
        const client = await clientRef.get()
        commit('setClient', new Client(await dispatch('prepareUser', client.data()), client.id))
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async editClient ({ dispatch, commit }, { id, registrationRecord }) {
      try {
        const client = await clientsCollection.doc(id).get()
        if (client.exists) {
          const clientPin = functions.httpsCallable('client-pin')

          const fileRef = storage.ref(`clients/${id}/registrationRecord`)
          const data = client.data()

          if (registrationRecord === null) {
            client.ref.update({ registrationRecord: firebase.firestore.FieldValue.delete() })
            try {
              await fileRef.getDownloadURL()
              await fileRef.delete()
            } finally {
              delete data.registrationRecord
              const response = await clientPin({ id, pin: false })
              if (response.data.expireAt) {
                data.expireAt = new Date(response.data.expireAt)
              }
            }
          } else if (registrationRecord instanceof Blob) {
            const snapshot = await fileRef.put(registrationRecord)
            const fileUrl = await snapshot.ref.getDownloadURL()

            await client.ref.update({ registrationRecord: fileUrl })
            data.registrationRecord = fileUrl
            const response = await clientPin({ id, pin: true })
            if (response.data.expireAt) {
              data.expireAt = new Date(response.data.expireAt)
            }
          }
          commit('setClient', new Client(await dispatch('prepareUser', data), client.id))
        } else {
          throw new CodeError('client/not-found', `Client with ID: ${id} doesnt exists`)
        }
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async extendClientRequest ({ dispatch, commit }, id) {
      try {
        const client = await clientsCollection.doc(id).get()
        if (client.exists) {
          client.ref.update({ extendRequested: true })
          const data = new Client(await dispatch('prepareUser', { ...client.data(), extendRequested: true }), client.id)
          commit('setClient', data)
          return data
        } else {
          throw new CodeError('client/not-found', `Client with ID: ${id} doesnt exists`)
        }
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async extendClient ({ dispatch, commit }, id) {
      try {
        const extend = functions.httpsCallable('client-extend')
        await extend(id)
        const client = await clientsCollection.doc(id).get()
        const data = new Client(await dispatch('prepareUser', client.data()), client.id)
        commit('setClient', data)
        return data
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async fetchClients ({ dispatch, commit, getters }) {
      try {
        const uid = await dispatch('getUid')
        let query = clientsCollection.orderBy('createdAt', 'desc')
        if (!getters.isStaff) {
          query = query.where('createdBy', '==', uid)
        }
        const clients = await Promise.all((await query.get()).docs.map(async doc => {
          return new Client(await dispatch('prepareUser', doc.data()), doc.id)
        }))
        commit('setClients', clients)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async getClientsChart ({ commit, dispatch, getters }, days = 7) {
      try {
        if (!getters.clients.length) {
          await dispatch('fetchClients')
        }

        const result = {
          labels: [],
          data: [],
          total: getters.clients.length,
        }
        for (let i = 0; i < days; i++) {
          const day = sub(new Date(), { days: i })
          const dayClients = getters.clients.reduce((accumulator, currentUser) => {
            return isSameDay(currentUser.createdAt, day) ? (accumulator + 1) : accumulator
          }, 0)
          result.labels.unshift(format(day, 'yyyy-MM-dd'))
          result.data.unshift(dayClients || 0)
        }
        return result
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },

  getters: {
    clients: s => s.clients,
    client: s => id => s.clients.find(client => client.id === id),
    activeClients: s => s.clients.filter(client => client.active) || [],
    inactiveClients: s => s.clients.filter(client => !client.active) || [],
  },
}
