<template>
  <form
    ref="form"
    @submit.prevent="submitHandler"
    class="form"
    autocomplete="off"
  >
    <slot />
  </form>
</template>

<script>
export default {
  name: 'MoleculeForm',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    validator: Object,
  },

  methods: {
    submit () {
      this.$refs.form.submit()
    },

    submitHandler (event) {
      this.$emit('submit', event)
    },
  },
}
</script>
