const capitalize = function capitalize (string) {
  if (!string) return ''

  string = string.toString()
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const date = function dateFilter (value, format = 'date') {
  if (!value) return
  const options = {}
  const date = value instanceof Date ? value : value?.toDate?.() || new Date(value)

  if (format.includes('date')) {
    options.day = '2-digit'
    options.month = 'long'
    options.year = 'numeric'
  }

  if (format.includes('time')) {
    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
  }

  return new Intl.DateTimeFormat('en', options).format(date)
}

const initials = function initials (value) {
  if (!value) return ''

  const names = value.split(' ')

  return names.length === 1 ? names[0][0] : names[0][0] + (names.length ? names[names.length - 1][0] : '')
}

const limit = function limit (string = '', length = 0) {
  return string ? string.substring(0, length) : ''
}

const truncate = function truncate (string = '', length = 0, etc = '...') {
  if (string.length <= length) {
    return string
  }

  return string.substring(0, length) + (string.length > length ? etc : '')
}

const formatBytes = (bytes, decimals = 0) => {
  if (bytes === 0) return '0 байт'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПТ', 'ЭБ', 'ЗБ', 'ЙТ']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

const formatPrice = price => price.toLocaleString('en', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })

const ellipsis = truncate

export {
  capitalize,
  date,
  initials,
  limit,
  truncate,
  ellipsis,
  formatBytes,
  formatPrice,
}
