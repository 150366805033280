<template>
  <a
    v-if="!!$slots.default"
    :href="'tel:' + $slots.default[0].text"
    class="text-reset fw-bolder"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'AtomPhone',
}
</script>
