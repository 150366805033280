<template>
  <MoleculeCard
    size="md"
    icon="question-mark"
    icon-color="blue"
    icon-size="lg"
  >
    <div class="row align-items-center">
      <div class="col">
        <h2 class="h1">{{ $t('support.title') }}</h2>
        <div class="markdown text-muted">
          <p>
            <i18n path="support.content">
              <template #link>
                <a
                  :href="$telegramLink"
                  target="_blank"
                >
                  {{ $t('support.link-text') }}
                </a>
              </template>
            </i18n>
          </p>
          <AtomDivider class="my-3 w-75" />
          <p>
            {{ $t('support.telegram.message') }}
          </p>
          <div class="mt-3">
            <AtomButton
              :href="$telegramLink"
              target="_blank"
              color="info"
            >
              <brand-telegram-icon />
              {{ $t('action.subscribe') }}
            </AtomButton>
          </div>
        </div>
      </div>
    </div>
  </MoleculeCard>
</template>

<script>
import MoleculeCard from '@/components/MoleculeCard'
import AtomButton from '@/components/AtomButton'
import AtomDivider from '@/components/AtomDivider'

export default {
  name: 'OrganismSupport',

  components: {
    MoleculeCard,
    AtomButton,
    AtomDivider,
  },
}
</script>
