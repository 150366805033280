import { projectsCollection } from '@/firebase'
import { CodeError } from '@/helpers'

export default {
  state: {
    projects: [],
  },

  mutations: {
    setProjects (state, projects) {
      state.projects = projects
    },

    clearProjects (state) {
      state.projects = []
    },
  },

  actions: {
    async fetchProjects ({ commit }) {
      try {
        const projects = (await projectsCollection.get()).docs.map(doc => {
          const data = doc.data()
          const url = data.url && new URL(`https://${data.url}`)

          return {
            id: +doc.id,
            link: url?.href,
            website: url?.hostname,
            ...data,
          }
        })

        commit('setProjects', projects)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async getProjectByAlias ({ commit }, alias) {
      try {
        if (!alias) {
          throw new CodeError('invalid-argument', 'The argument is not passed')
        }

        const snapshot = await projectsCollection.where('alias', '==', alias).get()
        if (!snapshot.empty) {
          const project = snapshot.docs[0]
          return { id: project.id, ...project.data() }
        }

        throw new CodeError('project/not-found', `Project with alias: ${alias} doesnt exists`)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },

  getters: {
    projects: s => s.projects.filter(project => project.id),
    allProjects: s => s.projects.filter(project => project.name),
  },
}
