import { firebase, articlesCollection, usersCollection, storage } from '@/firebase'
import { omit } from 'lodash'
import { ellipsis } from '@/filters'
import { CodeError } from '@/helpers'
import Vue from 'vue'

export default {
  state: {
    articles: [],
  },

  mutations: {
    setArticles (state, articles = []) {
      state.articles = articles
    },

    addArticle (state, article) {
      const index = state.articles.findIndex(c => c.id === article.id)
      if (index !== -1) {
        Vue.set(state.articles, index, article)
      } else {
        state.articles.push(article)
      }
    },

    removeArticle (state, id) {
      const index = state.articles.findIndex(c => c.id === id)
      if (index !== -1) {
        state.articles.splice(index, 1)
      }
    },

    clearArticles (state) {
      state.articles = []
    },
  },

  actions: {
    async createArticle ({ dispatch, commit }, { title, introtext = '', published, publishedAt, content, image, categoryKey }) {
      try {
        const uid = await dispatch('getUid')
        const article = await articlesCollection.add({
          title,
          introtext,
          published,
          content,
          categoryKey,
          createdBy: uid,
          createdUser: usersCollection.doc(uid),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          publishedAt: publishedAt ? firebase.firestore.Timestamp.fromDate(publishedAt) : firebase.firestore.FieldValue.serverTimestamp(),
        })
        const imageUrl = await dispatch('setArticleImage', { article, image })
        commit('addArticle', { title, introtext, published, content, id: article.id, image: imageUrl })
        return article.id
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async editArticle ({ dispatch, commit }, { title, introtext = '', published, publishedAt, content, id, image, categoryKey }) {
      try {
        const article = await articlesCollection.doc(id).get()
        if (article.exists) {
          const data = {
            title,
            introtext,
            published,
            content,
            categoryKey,
          }

          if (publishedAt) {
            data.publishedAt = firebase.firestore.Timestamp.fromDate(publishedAt)
          }

          article.ref.set(data, { merge: true })
          const imageUrl = await dispatch('setArticleImage', { article: article.ref, image })
          commit('addArticle', { title, introtext, published, content, id, image: imageUrl })
        } else {
          throw new CodeError('article/not-found', `Article with ID: ${id} doesnt exists`)
        }
        return id
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async removeArticle ({ dispatch, commit }, id) {
      try {
        if (!id) {
          throw new CodeError('article/not-found', 'Undefined article id')
        }

        const article = await articlesCollection.doc(id).get()
        if (article.exists) {
          await article.ref.delete()
          const imageRef = await dispatch('getImageRef', article.id)
          await dispatch('removeArticleImage', imageRef)
          commit('removeArticle', id)
        } else {
          throw new CodeError('article/not-found', `Article with ID: ${id} doesnt exists`)
        }
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async getArticleDataById ({ commit }, id) {
      try {
        const article = await articlesCollection.doc(id).get()
        if (article.exists) {
          const data = omit({ ...article.data(), id: article.id }, ['createdUser', 'createdBy'])

          if (data.publishedAt) {
            data.publishedAt = data.publishedAt.toDate()
          }

          return data
        } else {
          throw new CodeError('article/not-found', `Article with ID: ${id} doesnt exists`)
        }
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async fetchArticles ({ commit, getters }, categoryKey) {
      try {
        let query = articlesCollection.orderBy('publishedAt', 'desc')
        if (!getters.isStaff) {
          query = query.where('published', '==', true)
        }
        if (categoryKey) {
          query = query.where('categoryKey', '==', categoryKey)
        }
        const articles = (await query.get()).docs.map(doc => (omit({ ...doc.data(), id: doc.id }, ['createdUser', 'createdBy'])))
        commit('setArticles', articles)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async setArticleImage ({ dispatch, commit }, { article, image }) {
      try {
        let output = image
        const imageRef = await dispatch('getImageRef', article.id)

        if (image === null) {
          article.update({ image: firebase.firestore.FieldValue.delete() })
          await dispatch('removeArticleImage', imageRef)
        } else if (image instanceof Blob) {
          const snapshot = await imageRef.put(image)
          const imageUrl = await snapshot.ref.getDownloadURL()

          await article.update({ image: imageUrl })

          output = imageUrl
        }

        return output
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async removeArticleImage ({ commit }, image) {
      try {
        await image.getDownloadURL()
        await image.delete()
        return true
      } catch (e) {
        return false
      }
    },

    async getImageRef ({ commit }, id) {
      try {
        if (!id) {
          throw new CodeError('article/not-found', 'Undefined article id')
        }
        return storage.ref(`articles/${id}/cover`)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },

  getters: {
    articles: s => s.articles.map(article => ({ ...article, introtext: article.introtext || ellipsis(article.content, 200) })),
  },
}
