<template>
  <div>
    <AtomLabel
      v-if="label"
      :label="preparedLabel"
      :class="labelClasses"
    />
    <div :class="inputWrapperClass">
      <v-select
        v-if="searchable || showAvatar"
        :id="id"
        :options="preparedOptions"
        :placeholder="preparedPlaceholder"
        :label="valueLabel"
        :reduce="reduce"
        :disabled="isDisabled"
        :class="{ 'is-invalid': isInvalid }"
        :value="value"
        :clearable="clearable"
        :searchable="searchable"
        :filterable="filterable"
        @search="onSearch"
        @input="updateValue"
      >
        <template #no-options="{ searching }">
          <template v-if="searching">{{ $t('search.error.empty.title') }}</template>
          <template v-else>{{ $t('search.error.empty.title') }}</template>
        </template>
        <template #option="option">
          <div
            class="dropdown-item"
            :class="{ active: option.value === value }"
            role="option"
            :title="option.label"
          >
            <span
              v-if="showAvatar"
              class="dropdown-item-indicator"
            >
              <AtomAvatar
                :name="option.label"
                size="xs"
              />
            </span>
            {{ option.label }}
          </div>
        </template>
      </v-select>
      <select
        v-else
        class="form-select"
        :id="id"
        :value="value"
        :class="inputClasses"
        :disabled="isDisabled"
        @change="updateValue($event.target.value)"
      >
        <option
          v-if="clearable && placeholder"
          value=""
        >
          {{ preparedPlaceholder }}
        </option>
        <option
          v-for="option, idx in preparedOptions"
          :key="idx"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <small
        v-if="description || !!$slots.description"
        class="form-hint mt-2"
      >
        <slot name="description">{{ description }}</slot>
      </small>
    </div>
    <span
      v-if="error"
      class="invalid-feedback"
      v-text="error"
    />
  </div>
</template>

<script>
import AtomLabel from '@/components/AtomLabel'
import AtomAvatar from '@/components/AtomAvatar'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

export default {
  name: 'AtomSelect',

  components: {
    AtomLabel,
    AtomAvatar,
  },

  mixins: [FormMixin, InputMixin],

  props: {
    clearable: {
      type: Boolean,
      default: true,
    },

    searchable: {
      type: Boolean,
      default: false,
    },

    filterable: {
      type: Boolean,
      default: true,
    },

    valueLabel: {
      type: String,
      default: 'label',
    },

    value: [String, Number, Object, Array],

    options: {
      type: Array,
      default: () => [],
    },

    reduce: {
      type: Function,
      default: option => option.value || option,
    },

    showAvatar: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    preparedOptions () {
      return this.options.map(option => ({
        ...option,
        value: this.reduce(option),
        label: option[this.valueLabel] || (typeof option === 'string' || Number.isInteger(option) ? option : ''),
      }))
    },
  },

  methods: {
    onSearch (query, loading) {
      this.$emit('search', query, loading, this)
    },
  },
}
</script>

<style lang="scss">
  @import "~bootstrap/scss/functions";
  @import '~@tabler/core/src/scss/variables';

  $vs-colors: (
    lightest: $border-color,
    light: $border-color,
    dark: $gray-700,
    darkest: rgba($dark, 0.15),
  );

  $vs-component-placeholder-color: $input-placeholder-color;
  $vs-component-line-height: $input-btn-line-height + .2;
  $vs-border-width: $border-width;
  $vs-border-radius: $input-border-radius;

  $vs-state-active-bg: $active-bg;
  $vs-state-active-color: $blue;

  @import '~vue-select/src/scss/vue-select';

  .vs {
    $self: &;

    &--disabled {
      #{$self}__dropdown-toggle,
      #{$self}__clear,
      #{$self}__search,
      #{$self}__selected,
      #{$self}__open-indicator {
        background-color: $light;

        .theme-dark & {
          background-color: $dark;
        }
      }

      #{$self}__selected {
        color: $text-muted;
      }
    }

    &--single {
      &:not(#{$self}--open) #{$self}__selected + #{$self}__search {
        width: 0;
        padding: 0;
        margin: 0;
        border: none;
        height: 0;
      }

      #{$self}__search {
        .theme-dark & {
          color: $dark-mode-text;
        }
      }

      #{$self}__selected {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;

        .theme-dark & {
          color: $dark-mode-text;
        }

        &-options {
          width: 0;
        }
      }
    }

    &__dropdown {
      &-menu {
        min-width: 200px;
        overflow-x: hidden;

        .theme-dark & {
          background-color: $dark-mode-darken;
          border-color: $dark-mode-border-color;
        }
      }

      &-option {
        padding: 0;

        .theme-dark & {
          color: $dark-mode-text;
        }

        &--highlight {
          background-color: inherit;
          color: inherit;
        }
      }

      &-toggle {
        background: $white;

        .theme-dark & {
          background-color: $dark-mode-darken;
          color: $dark-mode-text;
          border-color: $dark-mode-border-color;
        }

        .is-invalid & {
          border-color: $danger;
        }
      }
    }

    &__open-indicator {
      fill: none;
      color: $secondary;
    }

    &__clear {
      color: $secondary;
    }
  }
</style>
