<template>
  <MoleculeModal
    body-class="text-center py-4"
    size="sm"
    :show="show"
    :status="dialog.status"
    @close="handleClickButton($event, false)"
  >
    <template slot="body">
      <component
        v-if="dialog.icon.name"
        :is="dialog.icon.name + '-icon'"
        class="mb-2 icon-lg"
        :class="'text-' + dialog.icon.status"
      />
      <h3 v-if="dialog.title">{{ dialog.title }}</h3>
      <div
        v-if="dialog.message"
        class="text-muted"
        v-text="dialog.message"
      />
    </template>
    <template slot="footer">
      <div class="w-100">
        <div class="row">
          <div
            v-for="button, idx in dialog.buttons"
            :key="idx"
            class="col"
          >
            <AtomButton
              class="w-100"
              :color="button.color"
              @click="handleClickButton($event, button.confirm)"
            >
              {{ button.text }}
            </AtomButton>
          </div>
        </div>
      </div>
    </template>
  </MoleculeModal>
</template>

<script>
import { events } from './events'

import MoleculeModal from '@/components/MoleculeModal'
import AtomButton from '@/components/AtomButton'

export default {
  name: 'Confirm',

  components: {
    MoleculeModal,
    AtomButton,
  },

  data () {
    return {
      show: false,

      dialog: {
        title: undefined,
        message: undefined,
        status: undefined,

        icon: {
          name: undefined,
          status: '',
        },

        buttons: [
          this.$t('action.no'),
          {
            text: this.$t('action.yes'),
            color: 'primary',
            confirm: true,
          },
        ],
      },

      callback: () => {},

      params: {},
    }
  },

  watch: {
    $route () {
      this.show = false
    },
  },

  mounted () {
    this.$options.initialData = { ...this.dialog }

    events.$on('open', this.open)
    events.$on('close', this.close)
  },

  methods: {
    resetState () {
      this.show = false
      this.dialog = { ...this.$options.initialData }
    },

    handleClickButton (e, confirm) {
      this.show = false

      if (typeof this.params.callback === 'function') {
        this.params.callback(confirm)
      }
    },

    open (options) {
      this.resetState()
      this.construct(options)

      this.show = true
    },

    construct (options) {
      this.params = options

      Array.from(['title', 'message', 'status']).forEach(option => { this.dialog[option] = options[option] })

      const buttons = options.buttons || this.dialog.buttons
      this.dialog.buttons = buttons.map(btn => {
        let data = {}
        if (typeof btn === 'string') {
          data.text = btn
        } else if (btn instanceof Object) {
          data = {
            ...btn,
            color: btn.confirm ? options.status || btn.color : btn.color,
          }
        }

        return data
      })

      if (typeof options.icon === 'string') {
        this.dialog.icon.name = options.icon
        this.dialog.icon.status = options.status || 'primary'
      } else if (options.icon instanceof Object) {
        this.dialog.icon.name = options.icon.name
        this.dialog.icon.status = options.icon.status || options.status || 'primary'
      }
    },
  },
}
</script>
