const colors = [
  'blue',
  'azure',
  'indigo',
  'purple',
  'pink',
  'red',
  'orange',
  'yellow',
  'lime',
  'green',
  'teal',
  'cyan',
]

const themeColors = [
  'primary',
  'secondary',
  'success',
  'info',
  'warning',
  'danger',
  'light',
  'white',
  'dark',
  'muted',
]

const allColors = [...colors, ...themeColors]

const perPages = [5, 10, 20]

const getThemes = function () {
  return [
    {
      label: this.$t('theme.system'),
      icon: 'settings-automation',
      value: '',
    },
    {
      label: this.$t('theme.dark'),
      icon: 'moon',
      value: 'dark',
    },
    {
      label: this.$t('theme.light'),
      icon: 'sun',
      value: 'light',
    },
  ]
}

const getFullname = function getFullname (obj = {}) {
  if (!Object.prototype.hasOwnProperty.call(obj, 'surname') && !Object.prototype.hasOwnProperty.call(obj, 'name')) return

  const fullname = []
  Array.from(['surname', 'name', 'patronymic']).forEach(field => obj[field] ? fullname.push(obj[field]) : '')

  return fullname.join(' ')
}

const getColorClass = function getColorClass (value, options = { prefix: 'bg', postfix: 'lt' }) {
  if (!value) {
    return colors[0]
  }
  let hash = 0
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash)
  }
  const colorIndex = Math.abs(hash % colors.length)
  const output = new Array(colors[colorIndex])

  if (options.prefix) {
    output.unshift(options.prefix)
  }

  if (options.postfix) {
    output.push(options.postfix)
  }

  return output.join('-')
}

const fileReader = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})

const getClosestParent = function (name, vm) {
  if (!vm || !name) return false

  let component = null
  let parent = vm.$parent
  while (parent && !component) {
    if (parent.$options.name === name) {
      component = parent
    }
    parent = parent.$parent
  }

  return component
}

const getClosestValidator = function (vm) {
  if (!vm) return false

  let validator = null
  let parent = vm.$parent
  while (parent && !validator) {
    if (parent.$v) {
      validator = parent.$v
    }
    parent = parent.$parent
  }

  return validator
}

const openLogoutModal = async function openLogoutModal () {
  this.$confirm({
    message: this.$t('logout.sure'),
    status: 'primary',
    buttons: [
      this.$t('action.cancel'),
      {
        text: this.$t('action.logout'),
        confirm: true,
      },
    ],
    callback: async confirm => {
      if (confirm) {
        await this.$store.dispatch('logout')
      }
    }
  })
}

const isTouchDevice = () => (('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))

class CodeError extends Error {
  constructor (code, message = '') {
    super(message)
    this.code = code
    this.message = message
  }
}

class ScrollHelper {
  constructor (el = document.body) {
    this.el = el
  }

  hide () {
    const scrollY = this.getScrollPosition()
    const scrollBarWidth = this.getScrollbarWidth()
    this.el.style.position = 'fixed'
    this.el.style.width = '100%'
    this.el.style.top = `-${scrollY}px`
    this.el.style.overflow = 'hidden'
    this.el.style.paddingRight = `${scrollBarWidth}px`
  }

  show () {
    const scrollY = this.el.style.top
    this.el.style.position = ''
    this.el.style.top = ''
    this.el.style.overflow = ''
    this.el.style.width = ''
    this.el.style.paddingRight = ''
    window.scrollTo({
      left: 0,
      top: parseInt(scrollY || 0) * -1,
      behavior: 'instant',
    })
  }

  getScrollPosition () {
    return window.scrollY
  }

  getScrollbarWidth () {
    return window.innerWidth - document.documentElement.clientWidth
  }
}

export {
  colors,
  themeColors,
  allColors,
  perPages,
  getFullname,
  getColorClass,
  fileReader,
  getClosestParent,
  getClosestValidator,
  openLogoutModal,
  isTouchDevice,
  CodeError,
  ScrollHelper,
  getThemes,
}
