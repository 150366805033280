<template>
  <div>
    <AtomLabel
      v-if="label"
      :label="preparedLabel"
      :class="labelClasses"
      :counter="counter"
      :total="value.length"
      :limit="!!v && v.$params.maxLength ? v.$params.maxLength.max : null"
    >
      <template
        v-if="!!$slots.labelDescription"
        slot="description"
      >
        <slot name="labelDescription" />
      </template>
    </AtomLabel>
    <div :class="inputWrapperClass">
      <div :class="inputGroupClass">
        <span
          v-if="beforeInput || icon"
          class="input-group-text"
        >
          <span
            v-if="beforeInput"
            class="link-secondary"
          >
            {{ beforeInput }}
          </span>
          <span
            v-if="icon"
            class="link-secondary"
            :class="{'ms-2': beforeInput}"
          >
            <component :is="icon + '-icon'" />
          </span>
        </span>
        <input
          :type="preparedType"
          class="form-control"
          :id="id"
          :placeholder="preparedPlaceholder"
          :value="value"
          :class="inputClasses"
          :disabled="isDisabled"
          @input="updateValue($event.target.value)"
          v-mask="isPhone ? '+44 (###) #### ####' : false"
        />
        <span
          v-if="(clearable && value) || afterInput || isPassword || loading"
          class="input-group-text"
        >
          <span
            v-if="clearable && value"
            class="link-secondary"
            :class="{'pe-auto cursor-pointer': value}"
            @click.prevent="updateValue('')"
          >
            <x-icon v-if="value" />
          </span>
          <span
            v-if="afterInput"
            class="link-secondary"
            :class="{'ms-2': clearable}"
          >
            {{ afterInput }}
          </span>
          <span
            v-if="isPassword"
            class="link-secondary cursor-pointer user-select-none"
            :class="{'ms-2': clearable || afterInput}"
            @click.prevent="show = !show"
          >
            <eye-off-icon v-if="show" />
            <eye-icon v-else />
          </span>
          <span
            v-if="loading"
            class="link-secondary"
            :class="{'ms-2': clearable || afterInput || isPassword}"
          >
            <AtomSpinner size="sm" color="muted" />
          </span>
        </span>
      </div>
      <small
        v-if="description || !!$slots.description"
        class="form-hint"
      >
        <slot name="description">{{ description }}</slot>
      </small>
      <span
        v-if="error"
        class="invalid-feedback"
        v-text="error"
      />
    </div>
  </div>
</template>

<script>
import { mask } from '@titou10/v-mask'

import AtomLabel from '@/components/AtomLabel'
import AtomSpinner from '@/components/AtomSpinner'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

export default {
  name: 'AtomInput',

  components: {
    AtomLabel,
    AtomSpinner,
  },

  directives: {
    mask,
  },

  mixins: [FormMixin, InputMixin],

  props: {
    icon: String,

    beforeInput: String,
    afterInput: String,

    type: {
      type: String,
      default: 'text',
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    counter: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      validator: value => ['rounded', 'flush'].includes(value),
    },

    size: {
      type: String,
      validator: value => ['lg', 'sm'].includes(value),
    },
  },

  data () {
    return {
      show: false,
      initialType: this.type,
    }
  },

  computed: {
    inputGroupClass () {
      return {
        'input-group': true,
        'input-group-flat': this.clearable || this.isPassword || this.isLoading || (this.afterInput && (!this.isPassword || this.beforeInput)),
        'input-group-flat-disabled': this.isDisabled,
      }
    },

    _inputClass () {
      return this.afterInput && !this.isPassword && this.beforeInput ? 'pe-0 text-end' : ''
    },

    isPassword () {
      return this.initialType === 'password'
    },

    isPhone () {
      return this.initialType === 'phone'
    },

    preparedType () {
      return this.isPassword ? (this.show ? 'text' : 'password') : (this.isPhone ? 'text' : this.type)
    },
  },
}
</script>
