import { firebase, auth, usersCollection } from '@/firebase'
import router from '@/router'

export default {
  actions: {
    async login ({ commit }, { email, password }) {
      try {
        await auth.signInWithEmailAndPassword(email, password)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async register ({ dispatch, commit }, { name, surname, patronymic, phone, email, password }) {
      try {
        await auth.createUserWithEmailAndPassword(email, password)
        const uid = await dispatch('getUid')
        await usersCollection.doc(uid).set({
          name,
          surname,
          patronymic,
          phone,
          email,
          notifySms: true,
        })
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async updatePassword ({ commit, dispatch }, password) {
      try {
        const user = await dispatch('getCurrentUser')
        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, password.old)
        await user.reauthenticateWithCredential(credentials)
        await user.updatePassword(password.new)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async forgotPassword ({ commit }, email) {
      try {
        await auth.sendPasswordResetEmail(email)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async changePassword ({ commit }, { code, newPassword }) {
      try {
        await auth.confirmPasswordReset(code, newPassword)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async verifyPasswordResetCode ({ commit }, code) {
      try {
        return await auth.verifyPasswordResetCode(code)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async logout ({ commit }) {
      await auth.signOut()
      await router.push('/login?message=logout')
      commit('clearCompanies')
      commit('clearProjects')
      commit('clearInfo')
      commit('clearRoles')
      commit('clearClients')
      commit('clearArticles')
      commit('clearUsers')
      commit('clearNotifications')
      commit('clearFavorites')
      commit('clearBoard')
    },

    getUid () {
      const user = auth.currentUser
      return user ? user.uid : null
    },

    getCurrentUser () {
      return auth.currentUser
    },
  },
}
