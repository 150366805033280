export default {
  props: {
    id: String,

    labelClass: String,
    inputWrapperClass: String,

    label: {
      type: String,
      default: '',
    },

    value: {
      type: String,
      default: '',
    },

    placeholder: String,
    description: String,
  },

  computed: {
    labelClasses () {
      const classes = []

      if (this.labelClass) {
        classes.push(this.labelClass)
      }

      if (this.required) {
        classes.push('required')
      }

      if (this.isInvalid) {
        classes.push('is-invalid')
      }

      return classes
    },

    inputClasses () {
      const classes = {}

      if (this.isInvalid) {
        classes['is-invalid'] = true
      }

      if (this.variant) {
        classes[`form-control-${this.variant}`] = true
      }

      if (this.size) {
        classes[`form-control-${this.size}`] = true
      }

      return classes
    },

    required () {
      return !!this.v && !!(this.v?.$params?.required || typeof this.v?.$params.checked !== 'undefined')
    },

    isInvalid () {
      return !!this.v && this.v.$anyError
    },

    preparedPlaceholder () {
      return this.$te(`${this.placeholder}.placeholder`) ? this.$t(`${this.placeholder}.placeholder`) : this.$te(`${this.label}.placeholder`) ? this.$t(`${this.label}.placeholder`) : this.placeholder || ''
    },

    preparedLabel () {
      return this.$te(`${this.label}.label`) ? this.$t(`${this.label}.label`) : this.label
    },

    error () {
      if (!!this.v && this.v.$dirty && this.v.$anyError && this.v.$params) {
        const error = Object.keys(this.v.$params).find(type => !this.v[type])
        if (error && this.$te(`${this.label}.error.${error}`)) {
          return this.$t(`${this.label}.error.${error}`, this.getErrorParams(error))
        }
      }
    },
  },

  methods: {
    updateValue (value) {
      this.$emit('input', value)
    },

    getErrorParams (type) {
      if (!this.v) return

      switch (type) {
        case 'minLength':
          return { min: this.v.$params[type].min }
        case 'maxLength':
          return { max: this.v.$params[type].max }
      }
    },
  },
}
