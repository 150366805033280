<template>
  <div class="sticky-top">
    <header class="navbar navbar-expand-md navbar-light d-print-none">
      <div class="container-xl">
        <button
          class="navbar-toggler"
          :class="{ collapsed: menuCollapsed }"
          :aria-expanded="menuCollapsed"
          @click="menuCollapsed = !menuCollapsed"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
          <router-link
            to="/"
            tabindex="1"
          >
            <img
              src="../assets/logo.svg"
              width="110"
              height="32"
              alt="Broker"
              class="navbar-brand-image"
            />
          </router-link>
        </h1>
        <div class="navbar-nav flex-row order-md-last">
          <div class="nav-item d-none d-md-flex">
            <AtomButton
              :href="$telegramLink"
              target="_blank"
              color="info"
              variant="pill"
              class="me-2"
            >
              <brand-telegram-icon />
            </AtomButton>
          </div>
          <div class="nav-item d-none d-md-flex position-relative">
            <router-link
              to="/favorites"
              class="me-2 nav-link px-0"
            >
              <heart-icon />
              <div class="badge-wrapper">
                <AtomBadge
                  v-if="favorites.length"
                  :class="{ tada: state.favoriteUpdated }"
                  color="red"
                  variant="pill"
                  v-text="favorites.length"
                />
              </div>
            </router-link>
          </div>
          <div class="nav-item d-none d-md-flex">
            <router-link
              to="/notifications"
              class="nav-link px-0"
              custom
            >
              <bell-icon />
            </router-link>
          </div>
          <AtomDropdown
            v-model="dropdownShow"
            class="nav-item"
          >
            <MoleculeAvatarDetails
              tag="button"
              :title="info.fullname"
              :subtitle="info.position"
              icon-size="sm"
              class="nav-link text-reset"
              body-class="d-none d-md-block"
            />
            <template #dropdown>
              <router-link
                v-for="link in filteredDropdownLinks"
                :key="link.url"
                :to="link.url"
                class="dropdown-item"
                exact-active-class="active"
              >
                <component
                  :is="link.icon + '-icon'"
                  v-if="link.icon"
                  class="dropdown-item-icon"
                />
                {{ link.title }}
              </router-link>
              <div class="dropdown-divider"></div>
              <h6 class="dropdown-header">{{ $t('theme.label') }}</h6>
              <label
                v-for="item, index in getThemes()"
                :key="index"
                class="dropdown-item cursor-pointer"
                :class="{ active: choosedTheme === item.value }"
                role="button"
                tabindex="0"
                @click.prevent="changeTheme(item.value)"
              >
                <component
                  :is="item.icon + '-icon'"
                  v-if="item.icon"
                  class="dropdown-item-icon"
                  :class="{ 'text-primary': choosedTheme === item.value }"
                />
                {{ item.label }}
              </label>
              <div class="dropdown-divider"></div>
              <button
                @click.prevent="openLogoutModal"
                class="dropdown-item"
              >
                <logout-icon class="dropdown-item-icon" />
                {{ $t('action.logout') }}
              </button>
            </template>
          </AtomDropdown>
        </div>
        <div
          class="collapse navbar-collapse"
          :class="{ show: menuCollapsed }"
        >
          <div class="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center">
            <MoleculeMenu
              :links="links"
              class="navbar-nav"
              item-class="nav-item"
              link-class="nav-link"
              icon-wrapper-class="nav-link-icon d-md-none d-lg-inline-block"
            >
              <template #title="{ link }">
                <span class="nav-link-title position-relative">{{ link.title }}</span>
                <div class="badge-wrapper">
                  <AtomBadge
                    v-if="link.url === '/promotions'"
                    color="red"
                    variant="pill"
                    v-text="1"
                  />
                </div>
              </template>
            </MoleculeMenu>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { openLogoutModal, getThemes } from '@/helpers'

import MoleculeMenu from '@/components/MoleculeMenu'
import MoleculeAvatarDetails from '@/components/MoleculeAvatarDetails'
import AtomAvatar from '@/components/AtomAvatar'
import AtomDropdown from '@/components/AtomDropdown'
import AtomSpinner from '@/components/AtomSpinner'
import AtomBadge from '@/components/AtomBadge'
import AtomButton from '@/components/AtomButton'

export default {
  name: 'OrganismHeader',

  components: {
    MoleculeMenu,
    MoleculeAvatarDetails,
    AtomAvatar,
    AtomDropdown,
    AtomSpinner,
    AtomBadge,
    AtomButton,
  },

  data: () => ({
    dropdownShow: false,
    menuCollapsed: false,

    state: {
      loading: true,
      favoriteUpdated: false,
    },
  }),

  computed: {
    ...mapGetters(['info', 'isAdmin', 'isStaff', 'isSuperManager', 'isManager', 'choosedTheme', 'favorites']),

    links () {
      return [
        {
          title: this.$t('pages.home.title'),
          url: '/',
          exact: true,
          icon: 'users',
        },
        {
          title: this.$t('pages.projects.title'),
          url: '/projects',
          icon: 'briefcase',
        },
        {
          title: this.$t('pages.promotions.title'),
          url: '/promotions',
          icon: 'tag',
        },
        {
          title: this.$t('pages.news.title'),
          url: '/news',
          icon: 'news',
        },
      ]
    },

    dropdownLinks () {
      return [
        {
          title: this.$t('pages.users.title'),
          url: '/users',
          icon: 'users',
          superManager: true,
        },
        {
          title: this.$t('pages.profile.title'),
          url: '/profile',
          icon: 'user',
        },
        {
          title: this.$t('pages.profile.edit.title'),
          url: '/profile/edit',
          icon: 'settings',
        },
        {
          title: this.$t('pages.password.change.menutitle'),
          url: '/password/change',
          icon: 'lock-open',
        },
        {
          title: this.$t('pages.notifications.title'),
          url: '/notifications',
          icon: 'bell',
        },
        {
          title: this.$t('pages.favorites.title'),
          url: '/favorites',
          icon: 'heart',
        },
        {
          title: this.$t('pages.companies.title'),
          url: '/profile/companies',
          icon: 'building',
          manager: true,
        },
      ]
    },

    filteredDropdownLinks () {
      let filter

      if (this.isSuperManager) {
        filter = link => !link.admin
      } else if (this.isManager) {
        filter = link => !link.admin && !link.superManager
      } else {
        filter = link => !link.admin && !link.superManager && !link.manager
      }

      return this.isAdmin ? this.dropdownLinks : this.dropdownLinks.filter(filter)
    },
  },

  watch: {
    $route () {
      this.menuCollapsed = false
    },
  },

  async mounted () {
    try {
      await this.fetchFavorites()
    } catch {} finally {
      this.state.loading = false
    }
  },

  methods: {
    ...mapActions(['fetchFavorites', 'changeTheme']),

    getThemes,

    openLogoutModal,
  },
}
</script>
