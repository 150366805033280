<template>
  <transition
    v-on:before-enter="beforeEnter"
    v-on:after-enter="afterEnter"
    v-on:before-leave="beforeLeave"
    v-on:after-leave="afterLeave"
  >
    <div
      v-if="show"
      class="modal fade"
      :class="classes"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      aria-hidden="true"
      @mousedown.self.stop="close($event)"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        :class="'modal-' + size"
        role="document"
      >
        <div class="modal-content">
          <div
            v-if="title"
            class="modal-header"
          >
            <slot name="header">
              <h5 class="modal-title">{{ title }}</h5>
              <AtomButton
                variant="close"
                @click="close($event)"
              />
            </slot>
          </div>
          <AtomButton
            v-else
            variant="close"
            @click="close($event)"
          />
          <div
            v-if="status"
            class="modal-status"
            :class="'bg-' + status"
          />
          <div
            v-if="!!$slots.body"
            class="modal-body"
            :class="bodyClass"
          >
            <slot name="body" />
          </div>
          <div
            v-if="!!$slots.footer"
            class="modal-footer"
          >
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { allColors, getClosestValidator } from '@/helpers'

import AtomButton from '@/components/AtomButton'

const backdrop = document.createElement('div')
backdrop.classList.add('modal-backdrop', 'fade')

export default {
  name: 'MoleculeModal',

  components: {
    AtomButton,
  },

  props: {
    title: String,

    show: {
      type: Boolean,
      default: false,
    },

    bodyClass: String,

    status: {
      type: String,
      validator: value => allColors.includes(value),
    },

    size: {
      type: String,
      default: 'md',
      validator: value => ['sm', 'md', 'lg', 'xl'].includes(value),
    },

    variant: {
      type: String,
      default: 'blur',
      validator: value => ['blur'].includes(value),
    },
  },

  data: () => ({
    transitionDuration: 150,
  }),

  computed: {
    classes () {
      return {
        'modal-blur': this.variant === 'blur',
      }
    },
  },

  watch: {
    show (value) {
      if (!value) {
        this.resetValidator()
      } else {
        this.$emit('open')
      }
    },
  },

  beforeDestroy () {
    this.afterLeave()
  },

  methods: {
    close () {
      this.resetValidator()
      this.$emit('close')
    },

    resetValidator () {
      const validator = getClosestValidator(this)
      if (validator && typeof validator.$reset === 'function') {
        validator.$reset()
      }
    },

    beforeEnter () {
      if (!document) return
      document.body.classList.add('modal-open')
      document.body.appendChild(backdrop)
      setTimeout(() => backdrop.classList.add('show'), this.transitionDuration)
    },

    afterEnter (el) {
      el.classList.add('d-block')
      setTimeout(() => el.classList.add('show'), this.transitionDuration)
    },

    beforeLeave (el) {
      el.classList.remove('show')
      setTimeout(() => el.classList.add('d-block'), this.transitionDuration)
    },

    afterLeave () {
      if (!document) return
      document.body.classList.remove('modal-open')
      backdrop.classList.remove('show')

      setTimeout(() => document.body.hasChildNodes(backdrop) ? backdrop.remove() : '', this.transitionDuration)
      this.$emit('onClose')
    },
  },
}
</script>
