<template>
  <div class="page-wrapper">
    <div class="page-body">
      <div class="container-xl">
        <div class="row row-cards">
          <div class="col-12">
            <OrganismClientsGrid />
          </div>
          <div class="col-12">
            <OrganismSupport />
          </div>
        </div>
      </div>
    </div>
    <MoleculeModal
      body-class="py-4"
      size="sm"
      :show="telegramModalShow"
      @close="telegramModalShow = false"
    >
      <template #body>
        <h3 class="modal-title">{{ $t('support.telegram.title') }}</h3>
        <p>{{ $t('support.telegram.message') }}</p>
        <AtomButton
          :href="$telegramLink"
          target="_blank"
          color="info"
          class="w-100"
        >
          <brand-telegram-icon />
          {{ $t('action.subscribe') }}
        </AtomButton>
      </template>
    </MoleculeModal>
  </div>
</template>

<script>
import OrganismClientsGrid from '@/components/OrganismClientsGrid'
import OrganismSupport from '@/components/OrganismSupport'
import MoleculeModal from '@/components/MoleculeModal'
import AtomButton from '@/components/AtomButton'

export default {
  title: vm => vm.$t('pages.home.title'),

  components: {
    OrganismClientsGrid,
    OrganismSupport,
    MoleculeModal,
    AtomButton,
  },

  data: () => ({
    telegramModalShow: false,
  }),

  mounted () {
    if (this.$route.params.register) {
      setTimeout(() => { this.telegramModalShow = true }, 1000)
    }
  },
}
</script>
