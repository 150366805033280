<template>
  <footer class="footer footer-transparent d-print-none">
    <div class="container">
      <div class="row text-center align-items-center flex-row-reverse">
        <div class="col-lg-auto ms-lg-auto">
          <i18n path="support.content">
            <template #link>
              <a
                :href="$telegramLink"
                target="_blank"
              >
                {{ $t('support.link-text') }}
              </a>
            </template>
          </i18n>
        </div>
        <div class="col-12 col-lg-auto mt-3 mt-lg-0">
          <div class="text-muted">
            © {{ new Date().getFullYear() }}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'OrganismFooter',
}
</script>
