<template>
  <div class="empty">
    <div
      v-if="header"
      class="empty-header"
      v-text="header"
    />
    <div
      v-else
      class="empty-icon"
    >
      <components :is="icon + '-icon'" />
    </div>
    <p
      class="empty-title"
      v-text="title"
    >
    <p
      v-if="message || !!$slots.message"
      class="empty-subtitle text-muted"
    >
      <slot name="message">{{ message }}</slot>
    </p>
    <div
      v-if="!!$slots.default"
      class="empty-action"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoleculeEmpty',

  props: {
    icon: {
      type: String,
      default: 'mood-confuzed',
    },

    header: String,

    title: {
      type: String,
      default () {
        return this.$t('search.error.empty.title')
      },
    },

    message: {
      type: String,
      default () {
        return this.$t('search.error.empty.message')
      },
    },
  },
}
</script>
