import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import fb from 'firebase/app'
import 'firebase/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      meta: {
        layout: 'main',
        auth: true,
      },
      component: Home,
    },
    {
      path: '/login',
      name: 'Login',
      meta: {
        layout: 'empty',
        guest: true,
      },
      component: () => import('../views/Login.vue'),
    },
    {
      path: '/password/forgot',
      name: 'Forgot password',
      meta: {
        layout: 'empty',
        guest: true,
      },
      component: () => import('../views/ForgotPassword.vue'),
    },
    {
      path: '/password/reset',
      name: 'Reset password',
      meta: {
        layout: 'empty',
        guest: true,
      },
      component: () => import('../views/ResetPassword.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      meta: {
        layout: 'empty',
        guest: true,
      },
      component: () => import('../views/Register.vue'),
    },
    {
      path: '/news',
      name: 'News',
      meta: {
        layout: 'main',
        auth: true,
        categoryKey: 'news',
      },
      component: () => import('../views/ArticleList.vue'),
    },
    {
      path: '/promotions',
      name: 'Promotions',
      meta: {
        layout: 'main',
        auth: true,
        categoryKey: 'promotions',
      },
      component: () => import('../views/ArticleList.vue'),
    },
    {
      path: '/article/create',
      name: 'Create article',
      meta: {
        layout: 'main',
        action: 'create',
        auth: true,
        manager: true,
      },
      component: () => import('../views/CreateEditArticle.vue'),
    },
    {
      path: '/article/edit/:id',
      name: 'Edit article',
      meta: {
        layout: 'main',
        action: 'edit',
        auth: true,
        manager: true,
      },
      component: () => import('../views/CreateEditArticle.vue'),
    },
    {
      path: '/article/:id',
      name: 'Article',
      meta: {
        layout: 'main',
        auth: true,
      },
      component: () => import('../views/Article.vue'),
    },
    {
      path: '/projects',
      name: 'Projects',
      meta: {
        layout: 'main',
        auth: true,
      },
      component: () => import('../views/Projects.vue'),
    },
    {
      path: '/board/:alias',
      name: 'Board',
      meta: {
        layout: 'main',
        auth: true,
      },
      component: () => import('../views/Board.vue'),
    },
    {
      path: '/favorites',
      name: 'Favorites',
      meta: {
        layout: 'main',
        auth: true,
      },
      component: () => import('../views/Favorites.vue'),
    },
    {
      path: '/download/presentation',
      name: 'DownloadPresentation',
      meta: {
        layout: 'empty',
      },
      component: () => import('../views/DownloadPresentation.vue'),
    },
    {
      path: '/agreement',
      name: 'Agreement',
      meta: {
        layout: 'empty',
      },
      component: () => import('../views/Agreement.vue'),
    },
    {
      path: '/users',
      name: 'Users',
      meta: {
        layout: 'profile',
        auth: true,
        superManager: true,
      },
      component: () => import('../views/Users.vue'),
    },
    {
      path: '/profile',
      name: 'Profile',
      meta: {
        layout: 'profile',
        auth: true,
      },
      component: () => import('../views/Profile.vue'),
    },
    {
      path: '/profile/edit',
      name: 'Profile Edit',
      meta: {
        layout: 'profile',
        auth: true,
      },
      component: () => import('../views/ProfileEdit.vue'),
    },
    {
      path: '/password/change',
      name: 'Change Password',
      meta: {
        layout: 'profile',
        auth: true,
      },
      component: () => import('../views/ChangePassword.vue'),
    },
    {
      path: '/notifications',
      name: 'Notifications',
      meta: {
        layout: 'profile',
        auth: true,
      },
      component: () => import('../views/Notifications.vue'),
    },
    {
      path: '/profile/companies',
      name: 'Companies',
      meta: {
        layout: 'profile',
        auth: true,
        manager: true,
      },
      component: () => import('../views/Companies.vue'),
    },
    {
      path: '/filemanager',
      name: 'File Manager',
      meta: {
        layout: 'main',
        auth: true,
      },
      component: () => import('../views/FileManager.vue'),
    },
    {
      path: '/404',
      alias: '*',
      name: '404',
      meta: {
        layout: 'empty',
      },
      component: () => import('../views/404.vue'),
    },
  ]
})

router.beforeEach(async (to, from, next) => {
  let admin = false
  let manager = false
  let superManager = false

  const currentUser = fb.auth().currentUser

  const requireAuth = to.matched.some(route => route.meta.auth)
  const requireGuest = to.matched.some(route => route.meta.guest)
  const requireAdmin = to.matched.some(route => route.meta.admin)
  const requireSuperManager = to.matched.some(route => route.meta.superManager)
  const requireStaff = to.matched.some(route => route.meta.manager)

  if (currentUser) {
    const token = await currentUser.getIdTokenResult()
    admin = token.claims.admin
    manager = token.claims.manager
    superManager = manager && token.claims.accessLevel >= 2
  }

  switch (true) {
    case requireAuth && !currentUser:
      next('/login')
      break
    case requireAdmin && !admin:
    case requireStaff && (!admin && !manager):
    case requireSuperManager && (!admin && !superManager):
    case requireGuest && !!currentUser:
      next('/')
      break
    default:
      next()
  }
})

export default router
