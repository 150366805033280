import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

firebase.initializeApp({
  apiKey: process.env.VUE_APP_API_KEY,
  authDomain: process.env.VUE_APP_KEY_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_PROJECT_ID,
  storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_ID,
})

const db = firebase.firestore()
const auth = firebase.auth()
const database = firebase.database()
const storage = firebase.storage()
const functions = firebase.functions()

const clientsCollection = db.collection('clients')
const usersCollection = db.collection('users')
const companiesCollection = db.collection('companies')
const articlesCollection = db.collection('articles')
const notificationsCollection = db.collection('notifications')
const projectsCollection = db.collection('projects')
const favoritesCollection = db.collection('favorites')

export {
  firebase,
  db,
  database,
  auth,
  storage,
  functions,
  clientsCollection,
  usersCollection,
  companiesCollection,
  articlesCollection,
  notificationsCollection,
  projectsCollection,
  favoritesCollection,
}
