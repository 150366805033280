<template>
  <div
    class="alert"
    :class="classes"
    role="alert"
  >
    <div class="d-flex">
      <div v-if="icon">
        <component
          :is="icon + '-icon'"
          class="alert-icon"
        />
      </div>
      <div v-if="title || message || !!$slots.default">
        <h4
          v-if="title"
          class="alert-title"
        >
          {{ title }}
        </h4>
        <div class="text-muted"><slot>{{ message }}</slot></div>
      </div>
    </div>
    <AtomButton
      @click="$emit('close')"
      variant="close"
    />
  </div>
</template>

<script>
import { themeColors } from '@/helpers'

import AtomButton from '@/components/AtomButton'

export default {
  name: 'MoleculeAlert',

  components: {
    AtomButton,
  },

  props: {
    dismissible: {
      type: Boolean,
      default: true,
    },

    variant: {
      type: String,
      validator: value => ['important'].includes(value),
    },

    color: {
      type: String,
      validator: value => themeColors.includes(value),
    },

    title: String,
    message: String,

    icon: String,
  },

  computed: {
    classes () {
      const classes = {}

      if (this.color) {
        classes[`alert-${this.color}`] = true
      }

      if (this.variant) {
        classes[`alert-${this.variant}`] = true
      }

      if (this.dismissible) {
        classes['alert-dismissible'] = true
      }

      return classes
    },
  },
}
</script>
