<template>
  <div :class="classes"><slot /></div>
</template>

<script>
import { allColors } from '@/helpers'

export default {
  name: 'AtomDivider',

  props: {
    color: {
      type: String,
      validator: value => allColors.includes(value),
    },

    align: {
      type: String,
      validator: value => ['left', 'right', 'center'].includes(value),
    },
  },

  computed: {
    classes () {
      const classes = {}

      if (this.$slots.default) {
        classes['hr-text'] = true
      } else {
        classes.hr = true
      }

      if (this.color) {
        classes[`text-${this.color}`] = true
      }

      if (this.align) {
        classes[`hr-text-${this.align}`] = true
      }

      return classes
    },
  },
}
</script>
