<template>
  <component
    :is="tag"
    v-bind="$attrs"
    class="badge"
    :class="classes"
  >
    <slot />
  </component>
</template>

<script>
import { allColors } from '@/helpers'

export default {
  name: 'AtomBadge',

  props: {
    color: {
      type: String,
      validator: value => allColors.includes(value.replace('lt', '')),
    },

    variant: {
      type: String,
      validator: value => ['outline', 'pill'].includes(value),
    },
  },

  computed: {
    classes () {
      const classes = {}

      if (this.variant) {
        classes[`badge-${this.variant}`] = true
      }

      if (this.color) {
        classes[`bg-${this.color}`] = true
      }

      return classes
    },

    tag () {
      return this.$attrs.href ? 'a' : this.$attrs.to ? 'router-link' : 'span'
    },
  },
}
</script>
