<template>
  <component
    :is="tag"
    v-bind="$attrs"
    v-on="$listeners"
    class="d-flex py-1 align-items-center"
  >
    <AtomAvatar
      v-if="icon || title || image"
      :name="!image ? title : null"
      :size="iconSize"
      :color="avatarColor || null"
      :image="image"
      class="me-2"
    >
      <slot>
        <component :is="icon + '-icon'" v-if="icon" />
      </slot>
    </AtomAvatar>
    <div
      class="flex-fill"
      :class="bodyClass"
    >
      <div
        class="fw-bold"
        :class="[titleClass, { 'text-truncate': !!truncate }]"
        :style="truncate ? { width: truncate } : null"
        :title="title"
      >
        <slot name="title">{{ title }}</slot>
      </div>
      <div
        v-if="subtitle || !!$slots.subtitle"
        class="text-muted"
      >
        <slot name="subtitle">{{ subtitle }}</slot>
      </div>
    </div>
  </component>
</template>

<script>
import AtomAvatar from '@/components/AtomAvatar'

export default {
  name: 'MoleculeAvatarDetails',

  components: {
    AtomAvatar,
  },

  props: {
    title: {
      type: String,
    },

    subtitle: String,

    avatarColor: {
      type: String,
    },

    icon: {
      type: String,
    },

    iconSize: {
      type: String,
      validator: value => ['xl', 'lg', 'md', 'sm', 'xs'].includes(value),
    },

    tag: {
      type: String,
      default: 'div',
      validator: value => ['div', 'span', 'button'].includes(value),
    },

    bodyClass: {
      type: [String, Array, Object],
    },

    titleClass: {
      type: [String, Array, Object],
    },

    image: {
      type: String,
    },

    truncate: {
      type: String,
    },
  },
}
</script>
