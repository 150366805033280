import axios from 'axios'

const API_URL = 'https://tessin.ru/assets/components/tessin/board.php'

export default {
  state: {
    board: {},
  },

  mutations: {
    setBoard (state, board = {}) {
      state.board = board
    },

    clearBoard (state) {
      state.board = {}
    },
  },

  actions: {
    async fetchBoard ({ commit }, houseId) {
      try {
        const response = await axios.get(API_URL, { params: { houseId } })
        commit('setBoard', response.data)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async getProperty ({ commit }, propertyId) {
      try {
        const response = await axios.get(API_URL, { params: { action: 'property', propertyId } })
        const property = response.data
        const title = property.custom_fields.find(prop => prop.id === 'pbcf_5cc170712aca4' && prop.value)
        property.title = title ? `${title.value} № ${property.number}` : `№ ${property.number}`
        return property
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },

  getters: {
    board: s => s.board,
  },
}
