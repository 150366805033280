<template>
  <component
    :is="tag"
    class="form-label"
  >
    <slot :label="label">{{ label }}</slot>
    <span
      v-if="counter || !!$slots.description || description"
      class="form-label-description"
    >
      <slot name="description">{{ description }}</slot>
      <template v-if="counter">{{ total }}<template v-if="limit">/{{ limit }}</template></template>
    </span>
  </component>
</template>

<script>
export default {
  name: 'AtomLabel',

  props: {
    label: String,
    description: String,

    counter: {
      type: Boolean,
      default: false,
    },

    total: Number,
    limit: Number,

    tag: {
      type: String,
      default: 'label',
      validator: value => ['div', 'span', 'label'].includes(value),
    },
  },
}
</script>
