import { isEmpty } from 'lodash'
import { getFullname } from '@/helpers'
import { usersCollection } from '@/firebase'

export default {
  state: {
    info: {},

    roles: {
      admin: false,
      manager: false,
    },
  },

  mutations: {
    setInfo (state, info) {
      state.info = info
    },

    clearInfo (state) {
      state.info = {}
    },

    setRoles (state, roles) {
      state.roles = roles
    },

    clearRoles (state) {
      state.roles = {
        admin: false,
        manager: false,
      }
    },
  },

  actions: {
    async fetchInfo ({ dispatch, commit }) {
      try {
        const user = await dispatch('getCurrentUser')
        const { email, emailVerified } = user
        let info = (await usersCollection.doc(user.uid).get()).data()

        if (info.company) {
          const companyDoc = await info.company.get()

          if (companyDoc.exists) {
            info.company = companyDoc.data()
          }
        }

        info = {
          ...info,
          uid: user.uid,
          email,
          emailVerified,
        }

        const token = await user.getIdTokenResult()
        const roles = {
          admin: !!token.claims.admin,
          manager: !!token.claims.manager,
          accessLevel: token.claims.accessLevel || 1,
        }

        commit('setInfo', info)
        commit('setRoles', roles)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async updateInfo ({ dispatch, commit, getters }, toUpdate) {
      try {
        const _uid = await dispatch('getUid')
        const updateData = { ...getters.info, ...toUpdate }
        const { emailVerified, fullname, company, companyId, uid, ...data } = updateData
        await usersCollection.doc(_uid).update(data)
        commit('setInfo', data)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },

  getters: {
    info: s => {
      const info = s.info
      if (!isEmpty(info)) {
        info.fullname = getFullname(info)
      }
      return info
    },

    roles: s => s.roles,
    isAdmin: s => s.roles.admin,
    isSuperManager: s => s.roles.manager && s.roles.accessLevel >= 2,
    isManager: s => s.roles.manager,
    isStaff: s => s.roles.admin || s.roles.manager,
    accessLevel: s => s.roles.accessLevel,
  },
}
