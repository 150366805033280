import { get } from 'lodash'
import { getClosestParent, getClosestValidator } from '@/helpers'

export default {
  props: {
    validator: Object,

    disabled: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    v: undefined,
    formWrapper: undefined,
  }),

  computed: {
    isDisabled () {
      return this.disabled || this.formWrapper?.disabled || false
    },

    isLoading () {
      return this.loading || this.formWrapper?.loading || false
    },

    modelName () {
      return this.$vnode?.data?.model?.expression
    },
  },

  mounted () {
    const formWrapper = getClosestParent('MoleculeForm', this)
    const validator = getClosestValidator(this)

    if (formWrapper) {
      this.formWrapper = formWrapper
    }

    if (validator && this.modelName) {
      this.v = get(validator, this.modelName) || this.validator || null
    }
  },
}
