<template>
  <span
    class="avatar"
    :class="classes"
    :style="style"
  >
    <AtomBadge
      v-if="status"
      :color="status"
    />
    <slot>{{ initials }}</slot>
  </span>
</template>

<script>
import { allColors, getColorClass } from '@/helpers'

import AtomBadge from '@/components/AtomBadge'

export default {
  name: 'AtomAvatar',

  components: {
    AtomBadge,
  },

  props: {
    name: String,

    image: String,

    color: {
      type: [String, Array, Object],
      validator (value) {
        if (value instanceof Object) {
          for (const [key] of Object.entries(value)) {
            if (!allColors.includes(key.replace('-lt', ''))) {
              return false
            }
          }
        } else if (Array.isArray(value)) {
          value.forEach(cls => {
            if (!allColors.includes(cls.replace('-lt', ''))) {
              return false
            }
          })
        } else {
          return allColors.includes(value.replace('-lt', ''))
        }

        return true
      },
    },

    status: {
      type: String,
      validator: value => allColors.includes(value),
    },

    variant: {
      type: String,
      validator: value => value.startsWith('rounded'),
    },

    size: {
      type: String,
      validator: value => ['xl', 'lg', 'md', 'sm', 'xs'].includes(value),
    },
  },

  computed: {
    initials () {
      return this.name ? this.$options.filters.initials(this.name) : ''
    },

    classes () {
      let classes = []

      if (this.color) {
        if (typeof this.color === 'string') {
          classes = this.color.replace(/\s+/g, ' ').trim().split(' ')
        }

        if (Array.isArray(classes)) {
          classes = classes.map(classname => `bg-${classname}`)
        }

        if (typeof this.color === 'object' && this.color !== null) {
          for (const [key, value] of Object.entries(this.color)) {
            if (value) {
              classes.push(`bg-${key}`)
            }
          }
        }
      } else if (this.name) {
        classes.push(getColorClass(this.name))
      }

      if (this.size) {
        classes.push(`avatar-${this.size}`)
      }

      return classes
    },

    style () {
      return this.image ? `background-image: url(${this.image})` : null
    },
  },
}
</script>
