<template>
  <div class="loading-wrapper">
    <AtomSpinner :color="color" />
  </div>
</template>

<script>
import { allColors } from '@/helpers'

import AtomSpinner from '@/components/AtomSpinner'

export default {
  name: 'MoleculeLoading',

  components: {
    AtomSpinner,
  },

  props: {
    color: {
      type: String,
      default: 'blue',
      validator: value => allColors.includes(value),
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "~bootstrap/scss/functions";
  @import '~@tabler/core/src/scss/variables';

  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($light, 0.8);
    z-index: 1000;

    .theme-dark & {
      background-color: rgba($dark, 0.8);
    }
  }
</style>
