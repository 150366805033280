import { companiesCollection } from '@/firebase'
import Vue from 'vue'

export default {
  state: {
    companies: [],
  },

  mutations: {
    setCompanies (state, companies) {
      state.companies = companies
    },

    setCompany ({ companies }, company) {
      const index = companies.findIndex(c => c.id === company.id)
      if (index !== -1) {
        Vue.set(companies, index, company)
      } else {
        companies.push(company)
      }
    },

    removeCompany (state, id) {
      const index = state.companies.findIndex(c => c.id === id)
      if (index !== -1) {
        state.companies.splice(index, 1)
      }
    },

    clearCompanies (state) {
      state.companies = []
    },
  },

  actions: {
    async fetchCompanies ({ commit }) {
      try {
        const companies = (await companiesCollection.get()).docs.map(doc => ({ ...doc.data(), id: doc.id }))
        commit('setCompanies', companies)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async editCompany ({ commit }, { id, name, phone, website }) {
      try {
        const company = await companiesCollection.doc(id)
        await company.update({ phone, website })
        commit('setCompany', { id, name, phone, website })
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    // async removeCompany ({ commit }, id) {
    //   try {
    //     if (!id) {
    //       throw new CodeError('company/not-found', 'Undefined company ID')
    //     }

    //     const company = await companiesCollection.doc(id).get()
    //     if (company.exists) {
    //       await company.ref.delete()
    //       commit('removeCompany', id)
    //     } else {
    //       throw new CodeError('company/not-found', `Company with ID: ${id} doesnt exists`)
    //     }
    //   } catch (e) {
    //     commit('setError', e)
    //     throw e
    //   }
    // },
  },

  getters: {
    companies: s => s.companies,
    company: s => id => s.companies.find(company => id === company.id),
  },
}
