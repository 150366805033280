import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import info from './info'
import client from './client'
// import avatar from './avatar'
import user from './user'
import project from './project'
import company from './company'
import article from './article'
import notification from './notification'
import theme from './theme'
import profitbase from './profitbase'
import favorite from './favorite'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    error: null,
  },

  mutations: {
    setError (state, error) {
      state.error = error
    },

    clearError (state) {
      state.error = null
    },
  },

  getters: {
    error: s => s.error
  },

  modules: {
    auth,
    info,
    client,
    // avatar,
    user,
    project,
    company,
    article,
    notification,
    theme,
    profitbase,
    favorite,
  },
})
