<template>
  <TemplateMain>
    <div class="page-wrapper">
      <div class="page-body">
        <div class="container-xl">
          <div class="row row-cards">
            <div class="col-12">
              <MoleculeCard>
                <template #content>
                  <div class="row g-0">
                    <div class="col-3 d-none d-md-block border-end">
                      <OrganismUserCard />
                    </div>
                    <div class="col d-flex flex-column">
                      <router-view />
                    </div>
                  </div>
                </template>
              </MoleculeCard>
            </div>
            <div class="col-12">
              <OrganismSupport />
            </div>
          </div>
        </div>
      </div>
    </div>
  </TemplateMain>
</template>

<script>
import { mapGetters } from 'vuex'

import MoleculeCard from '@/components/MoleculeCard'
import OrganismUserCard from '@/components/OrganismUserCard'
import OrganismSupport from '@/components/OrganismSupport'
import TemplateMain from '@/components/TemplateMain'

export default {
  name: 'TemplateProfile',

  components: {
    MoleculeCard,
    OrganismUserCard,
    OrganismSupport,
    TemplateMain,
  },

  computed: mapGetters(['info']),
}
</script>
