import Vue from 'vue'
import VuePageTitle from 'vue-page-title'
import Vuelidate from 'vuelidate'
import Paginate from 'vuejs-paginate'
import Notifications from 'vue-notification'
import TablerIcons, { XIcon, ChevronDownIcon } from 'vue-tabler-icons'
// import AvatarCropper from 'vue-avatar-cropper'
import VueApexCharts from 'vue-apexcharts'
import VueSelect from 'vue-select'
import App from './App'
import store from './store'
import router from './router'
import i18n from './i18n'
import { auth } from './firebase'

import * as filters from '@/filters'

import Confirm from '@/plugins/vue-confirm'

import '@/assets/scss/app.scss'

// import '@tabler/core/dist/js/tabler.min'
// window.bootstrap = require('bootstrap')

VueSelect.props.components.default = () => ({
  Deselect: XIcon,
  OpenIndicator: ChevronDownIcon,
})

Vue.use(VuePageTitle, {
  suffix: ` - ${process.env.VUE_APP_NAME}`
})
Vue.use(Vuelidate)
Vue.use(Notifications)
Vue.use(TablerIcons)
Vue.use(VueApexCharts)
Vue.use(Confirm)
// Vue.component('AvatarCropper', AvatarCropper)
Vue.component('Paginate', Paginate)
Vue.component('apexchart', VueApexCharts)
Vue.component('v-select', VueSelect)

for (const [name, filter] of Object.entries(filters)) {
  Vue.filter(name, filter)
}

Vue.config.productionTip = false
Vue.prototype.$telegramLink = 'https://t.me/codesolution_team'

let app

auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      i18n,
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
