import { firebase, favoritesCollection, usersCollection } from '@/firebase'
import Vue from 'vue'

export default {
  state: {
    favorites: [],
  },

  mutations: {
    setFavorites (state, favorites = []) {
      state.favorites = favorites
    },

    setFavorite (state, item) {
      const index = state.favorites.findIndex(i => i.id === item.id)
      if (index !== -1) {
        Vue.set(state.favorites, index, item)
      } else {
        state.favorites.unshift(item)
      }
    },

    removeFavorite (state, id) {
      const index = state.favorites.findIndex(i => i.id === id)
      if (index !== -1) {
        state.favorites.splice(index, 1)
      }
    },

    clearFavorites (state) {
      state.favorites = []
    },
  },

  actions: {
    async processFavorite ({ dispatch, commit }, { id, group }) {
      try {
        const uid = await dispatch('getUid')
        const items = await favoritesCollection.where('createdBy', '==', uid).get()
        const item = items.docs.find(item => item.data().id === id)
        const output = { id }

        if (item) {
          await item.ref.delete()
          commit('removeFavorite', id)
          output.value = false
        } else {
          const data = {
            id,
            group,
            createdBy: uid,
            createdUser: usersCollection.doc(uid),
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          }
          await favoritesCollection.add(data)
          commit('setFavorite', { ...data })
          output.value = true
        }
        return output
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async createFavorite ({ dispatch, commit }, { id, group }) {
      try {
        const uid = await dispatch('getUid')
        const data = {
          id,
          group,
          createdBy: uid,
          createdUser: usersCollection.doc(uid),
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        }
        await favoritesCollection.add(data)
        commit('setFavorite', { ...data })
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async removeFavorite ({ dispatch, commit }, { id }) {
      try {
        const uid = await dispatch('getUid')
        const items = await favoritesCollection.where('createdBy', '==', uid).get()
        const item = items.docs.find(item => item.data().id === id)

        if (item) {
          await item.ref.delete()
          commit('removeFavorite', id)
        } else {
          // Выбросить ошибку
        }
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async fetchFavorites ({ dispatch, commit }) {
      try {
        const uid = await dispatch('getUid')
        const favorites = (await favoritesCollection.where('createdBy', '==', uid).get()).docs.map(item => ({ ...item.data() }))
        commit('setFavorites', favorites)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },

  getters: {
    favorites: s => s.favorites,
  },
}
