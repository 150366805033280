import { omit } from 'lodash'
import { usersCollection } from '@/firebase'
import { getFullname } from '@/helpers'

export default {
  state: {
    users: [],
  },

  mutations: {
    setUsers (state, users) {
      state.users = users
    },

    clearUsers (state) {
      state.users = []
    },
  },

  actions: {
    async fetchUsers ({ commit }) {
      try {
        const users = (await usersCollection.get()).docs.map(user => {
          const data = omit(user.data(), ['company', 'companyId'])
          return {
            ...data,
            id: user.id,
            fullname: getFullname(data),
          }
        })
        commit('setUsers', users)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async prepareUser ({ getters }, data) {
      if (getters.isStaff && data.createdUser) {
        const user = await data.createdUser.get()

        if (user.exists) {
          const userData = omit(user.data(), ['defaultPerPage', 'role', 'notifySms'])
          data.createdUser = { ...userData, id: user.id, fullname: getFullname(userData) }

          if (userData.company) {
            const company = await userData.company.get()
            if (company.exists) {
              data.createdUser.company = company.data()
            }
          }
        } else {
          data.createdUser = null
        }
      }

      return data
    },
  },

  getters: {
    users: s => s.users.sort((a, b) => {
      if (a.fullname > b.fullname) {
        return 1
      }
      if (a.name < b.name) {
        return -1
      }
      return 0
    }),
  },
}
