<template>
  <component
    :is="tag"
    v-bind="$attrs"
    v-on="$listeners"
    class="card"
    :class="cardClasses"
  >
    <slot name="top" />
    <div
      v-if="loading && loadingType === 'placeholder'"
      class="ratio ratio-21x9 card-img-top placeholder"
    />
    <div
      v-else-if="image && imagePosition !== 'bottom'"
      class="card-img-top img-responsive"
      :class="imageClass"
      :style="{ backgroundImage: `url(${image})` }"
    />
    <div
      v-if="status"
      :class="statusClasses"
    />
    <div
      v-if="icon"
      class="card-stamp"
      :class="iconWrapperClasses"
    >
      <div
        class="card-stamp-icon"
        :class="iconClasses"
      >
        <component :is="iconName" />
      </div>
    </div>
    <div
      v-if="!!$slots.header || title"
      class="card-header"
      :class="headerClasses"
    >
      <slot name="header">
        <div>
          <h3
            v-if="title"
            class="card-title"
            v-text="title"
          />
          <p
            v-if="subtitle"
            class="card-subtitle"
            v-text="subtitle"
          />
        </div>
      </slot>
      <div
        v-if="!!$slots.actions"
        class="card-actions"
      >
        <slot name="actions" />
      </div>
    </div>
    <slot name="content">
      <template v-if="loading && loadingType === 'placeholder'">
        <div
          class="card-body"
          :class="bodyClass"
        >
          <div class="placeholder col-9 mb-3"></div>
          <div class="placeholder placeholder-xs col-10"></div>
          <div class="placeholder placeholder-xs col-11"></div>
        </div>
      </template>
      <div
        v-else-if="!!$slots.default"
        class="card-body"
        :class="bodyClass"
      >
        <slot />
      </div>
      <div
        v-if="!!$slots.footer"
        class="card-footer"
      >
        <slot name="footer" />
      </div>
      <div
        v-if="image && imagePosition === 'bottom'"
        class="card-img-bottom img-responsive"
        :class="imageClass"
        :style="{ backgroundImage: `url(${image})` }"
      />
      <slot name="bottom" />
    </slot>
    <MoleculeLoading v-if="loading && loadingType === 'spinner'" />
  </component>
</template>

<script>
import { allColors } from '@/helpers'

import MoleculeLoading from '@/components/MoleculeLoading'

export default {
  name: 'MoleculeCard',

  components: {
    MoleculeLoading,
  },

  props: {
    title: String,
    subtitle: String,

    disabled: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
      default: false,
    },

    stacked: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    loadingType: {
      type: String,
      default: 'spinner',
      validator: value => ['spinner', 'placeholder'].includes(value)
    },

    rotate: {
      type: String,
      validator: value => ['left', 'right'].includes(value),
    },

    hoverType: {
      type: String,
      validator: value => ['rotate', 'pop'].includes(value),
    },

    headerType: {
      type: String,
      validator: value => ['light'].includes(value),
    },

    icon: String,

    iconColor: {
      type: String,
      validator: value => allColors.includes(value),
    },

    iconSize: {
      type: String,
      validator: value => ['lg'].includes(value),
    },

    color: {
      type: String,
      validator: value => allColors.includes(value.endsWith('-lt') ? value.replace('-lt', '') : value),
    },

    status: {
      type: String,
      validator: value => allColors.includes(value),
    },

    statusPosition: {
      type: String,
      default: 'top',
      validator: value => ['top', 'bottom', 'left', 'start'].includes(value),
    },

    border: {
      type: Boolean,
      default: true,
    },

    outline: {
      type: Boolean,
      default: true,
    },

    bodyClass: {
      type: [String, Object, Array],
    },

    headerClass: {
      type: [String, Object, Array],
    },

    image: String,

    imagePosition: {
      type: String,
      default: 'top',
      validator: value => ['top', 'bottom'].includes(value),
    },

    imageClass: String,

    size: {
      type: String,
      validator: value => ['sm', 'md', 'lg'].includes(value),
    },
  },

  computed: {
    isLink () {
      return !!this.href || !!this.to
    },

    iconName () {
      return this.icon ? `${this.icon}-icon` : false
    },

    tag () {
      return this.$attrs.href ? 'a' : this.$attrs.to ? 'router-link' : 'div'
    },

    cardClasses () {
      const classes = {
        'card-inactive': this.disabled,
        'card-active': this.active,
        'card-link': this.isLink,
        'card-stacked': this.stacked,
        'card-borderless': !this.border,
        'border-0': !this.outline,
        'placeholder-glow': this.loading,
      }

      if (this.color) {
        classes[`bg-${this.color}`] = true
      }

      if (this.rotate) {
        classes[`card-rotate-${this.rotate}`] = true
      }

      if (this.hoverType && this.isLink) {
        classes[`card-link-${this.hoverType}`] = true
      }

      if (this.size) {
        classes[`card-${this.size}`] = true
      }

      return classes
    },

    iconClasses () {
      return this.iconColor ? `bg-${this.iconColor}` : null
    },

    iconWrapperClasses () {
      return this.iconSize ? `card-stamp-${this.iconSize}` : null
    },

    headerClasses () {
      let classes = this.headerClass

      if (this.headerType === 'light') {
        if (typeof classes === 'string') {
          classes += ' card-header-light'
        } else if (Array.isArray(classes)) {
          classes.push('card-header-light')
        } else if (classes instanceof Object && classes !== null) {
          classes['card-header-light'] = true
        }
      }

      return classes
    },

    statusClasses () {
      const classes = {}

      if (this.status) {
        classes[`bg-${this.status}`] = true
      }

      if (this.statusPosition) {
        const position = this.statusPosition.replace('left', 'start')
        classes[`card-status-${position}`] = true
      }

      return classes
    },
  },
}
</script>

<style lang="scss" important scoped>
  .card {
    $self: &;

    &-form {
      #{$self}-actions {
        margin: {
          top: 0;
          bottom: 0;
          right: 0;
        }
      }
    }

    &-unpublished {
      box-shadow: none;
      opacity: .5;
    }
  }
</style>
