<template>
  <div>
    <AtomLabel
      v-if="label"
      :label="preparedLabel"
      :class="labelClasses"
    >
      <template
        v-if="!!$slots.labelDescription"
        slot="description"
      >
        <slot name="labelDescription" />
      </template>
    </AtomLabel>
    <div :class="inputWrapperClass">
      <DatePicker
        v-bind="$props"
        inputClass="form-control"
        :disabled="isDisabled"
        :value="value"
        :placeholder="preparedPlaceholder"
        @input="updateValue"
        @clear="() => updateValue('')"
      >
        <template #icon-calendar>
          <calendar-icon />
        </template>
        <template #icon-clear>
          <x-icon />
        </template>
        <template #header="{ emit }">
          <slot name="header" :emit="emit"></slot>
        </template>
      </DatePicker>
      <small
        v-if="description || !!$slots.description"
        class="form-hint"
      >
        <slot name="description">{{ description }}</slot>
      </small>
      <span
        v-if="error"
        class="invalid-feedback"
        v-text="error"
      />
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import AtomLabel from '@/components/AtomLabel'

import FormMixin from '@/mixins/FormMixin'
import InputMixin from '@/mixins/InputMixin'

export default {
  name: 'AtomInput',

  components: {
    DatePicker,
    AtomLabel,
  },

  mixins: [FormMixin, InputMixin],

  props: {
    value: {
      type: [String, Number, Date, Array],
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    editable: {
      type: Boolean,
      default: false,
    },

    range: {
      type: Boolean,
      default: false,
    },

    disabledDate: {
      type: Function,
    },

    shortcuts: {
      type: Array,
    },
  },
}
</script>

<style lang="scss">
.mx-datepicker {
  width: 100%;

  svg {
    fill: none;
  }
}
</style>
