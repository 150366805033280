<template>
  <ul v-if="links.length">
    <router-link
      v-for="link in links"
      :key="link.url"
      :to="link.url"
      v-slot="{ href, navigate, isExactActive }"
      custom
    >
      <li :class="[itemClass, isExactActive ? itemActiveClass : '']">
        <a
          :href="href"
          :class="linkClass"
          @click="navigate"
          tabindex="2"
        >
          <slot>
            <span
              v-if="link.icon"
              :class="iconWrapperClass"
            >
              <component
                :is="link.icon + '-icon'"
                :class="iconClass"
              />
            </span>
            <slot
              name="title"
              :link="link"
            >
              {{ link.title }}
            </slot>
          </slot>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'MoleculeMenu',

  props: {
    links: {
      type: Array,
      default: () => [],
    },

    itemClass: {
      type: String,
    },

    itemActiveClass: {
      type: String,
      default: 'active',
    },

    linkClass: {
      type: String,
    },

    iconWrapperClass: {
      type: String,
    },

    iconClass: {
      type: String,
    },
  },
}
</script>
