<template>
  <MoleculeCard
   :border="border"
   :outline="outline"
  >
    <template slot="content">
      <div
        v-if="title || $slots.actions"
        class="card-header"
      >
        <h3
          v-if="title"
          class="card-title"
        >
          {{ title }}
          <span
            v-if="subtitle"
            class="card-subtitle"
            v-text="subtitle"
          />
        </h3>
        <div
          v-if="!!$slots.actions"
          class="card-actions"
        >
          <slot name="actions" />
        </div>
      </div>
      <div
        v-if="search.length || filters.length"
        class="card-body border-bottom py-3"
      >
        <div class="row row-cards">
          <div
            v-if="search.length"
            class="col-12 col-md-3"
          >
            <AtomInput
              v-model.trim="query"
              placeholder="search"
              icon="search"
              :clearable="true"
              @input="update"
              :disabled="loading || !rows.length"
            />
          </div>
          <div
            v-for="item in preparedFilters"
            :key="item.key"
            :class="item.class || 'col-12 col-md-2'"
          >
            <AtomDatePicker
              v-if="item.type === 'date'"
              v-model="filter[item.key]"
              :placeholder="item.placeholder"
              class="w-100"
              :disabled="loading || !rows.length"
              :editable="false"
              :disabled-date="date => date > new Date()"
              range
              @input="$emit('filterChange', { item, filter })"
            >
              <template #header="{ emit }">
                <button
                  v-for="shortcut, idx in dateShortcuts"
                  :key="idx"
                  class="mx-btn mx-btn-text"
                  @click="emit(shortcut.onClick())"
                >
                  {{ shortcut.text }}
                </button>
              </template>
            </AtomDatePicker>
            <AtomSelect
              v-else
              v-model="filter[item.key]"
              :options="item.options"
              :reduce="option => option.value"
              value-label="name"
              :placeholder="item.placeholder"
              class="w-100"
              :searchable="true"
              :disabled="loading || !rows.length"
              @input="$emit('filterChange', { item, filter })"
            />
          </div>
          <div
            v-if="search.length || filters.length"
            class="col-auto"
          >
            <AtomButton
              v-if="filterDirty && rows.length && !loading"
              variant="outline"
              color="danger"
              @click="clearFilterHandler"
              :title="$t('action.reset')"
            >
              <x-icon />
              <!-- {{ $t('action.reset') }} -->
            </AtomButton>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table card-table table-vcenter text-nowrap">
          <thead v-if="loading || items.length">
            <tr>
              <th
                v-for="column in columns"
                :key="column.key"
                :class="{ sortable: column.sortable && rows.length && !loading }"
                :style="column.style"
                :width="Number.isInteger(column.width) ? `${column.width}%` : column.width"
                @click.prevent="doSort($event, column)"
              >
                {{ column.name }}
                <button
                  v-if="column.sortable && rows.length && !loading"
                  tabindex="-1"
                  class="sortable-sort"
                  :class="{
                    'sortable-sort-neutral': sort.key !== column.key,
                    'sortable-sort-asc': sort.key === column.key && sort.direction === 'asc',
                    'sortable-sort-desc': sort.key === column.key && sort.direction === 'desc',
                  }"
                />
              </th>
            </tr>
          </thead>
          <tbody v-if="loading">
            <tr
              v-for="n, index in perPage"
              :key="index"
            >
              <td><div class="placeholder d-block"></div></td>
              <td>
                <div class="row flex-nowrap align-items-center my-1">
                  <div class="col-auto">
                    <div class="avatar avatar-rounded placeholder"></div>
                  </div>
                  <div class="col-7">
                    <div class="placeholder placeholder-xs d-block col-8 mb-2"></div>
                    <div class="placeholder placeholder-xs d-block col-6"></div>
                  </div>
                </div>
              </td>
              <td
                v-for="n in columns.length - 2"
                :key="n"
              >
                <div class="placeholder placeholder-xs d-block"></div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr
              v-for="row, rowIndex in items"
              :key="rowIndex"
            >
              <td
                v-for="column, index in columns"
                :key="index"
              >
                <slot
                  :name="column.key"
                  :data="row"
                  :index="row.index"
                  :idx="row.idx"
                >
                  {{ row[column.key] }}
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="loading"
        class="card-footer py-4"
      >
        <div class="row align-items-center justify-content-between">
          <div class="col-2">
            <div class="placeholder placeholder-xs d-block"></div>
          </div>
          <div class="col-4">
            <div class="placeholder placeholder-xs d-block"></div>
          </div>
        </div>
      </div>
      <div
        v-else-if="pagination && data.length > Math.min(...$options.perPages)"
        class="card-footer d-flex align-items-center"
      >
        <div class="text-muted">
          <div class="d-none d-md-inline-block">{{ $t('action.show') }}:</div>
          <AtomSelect
            v-model="perPage"
            :options="$options.perPages"
            class="mx-2 d-inline-block"
            @input="changePerPageHandler"
          />
        </div>
        <p
          v-if="items.length"
          class="m-0 text-muted"
        >
          {{ $t('pagination.showed') }}
          <span v-text="from" />
          -
          <span v-text="to" />
          {{ $t('pagination.from') }}
          <span v-text="total" />
        </p>
        <Paginate
          v-if="pageCount > 1"
          v-model="page"
          :page-count="pageCount"
          :click-handler="changePageHandler"
          :prev-text="$t('pagination.prev')"
          :next-text="$t('pagination.next')"
          container-class="pagination m-0 ms-auto"
          page-class="page-item"
          prev-class="page-item"
          next-class="page-item"
          prev-link-class="page-link"
          next-link-class="page-link"
          page-link-class="page-link"
        />
      </div>
      <MoleculeEmpty
        v-if="filterDirty && !items.length && rows.length"
        :title="$t('search.error.empty.title')"
      >
        <template
          v-if="query"
          slot="message"
        >
          <i18n path="search.error.empty.query">
            <template slot="query">
              <span class="fw-bolder">«{{ query }}»</span>
            </template>
          </i18n>
        </template>
      </MoleculeEmpty>
      <MoleculeEmpty
        v-else-if="!loading && !rows.length"
        :message="emptyMessage"
      >
        <slot name="empty" />
      </MoleculeEmpty>
    </template>
  </MoleculeCard>
</template>

<script>
import { chunk, size, has, get, some } from 'lodash'
import { perPages } from '@/helpers'

import MoleculeCard from '@/components/MoleculeCard'
import AtomInput from '@/components/AtomInput'
import AtomSelect from '@/components/AtomSelect'
import AtomDatePicker from '@/components/AtomDatePicker'
import AtomButton from '@/components/AtomButton'
import MoleculeEmpty from '@/components/MoleculeEmpty'

export default {
  name: 'MoleculeGrid',

  components: {
    MoleculeCard,
    AtomInput,
    AtomSelect,
    AtomDatePicker,
    AtomButton,
    MoleculeEmpty,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    title: String,
    subtitle: String,

    columns: {
      type: Array,
      default: () => [],
    },

    rows: {
      type: Array,
      default: () => [],
    },

    pagination: {
      type: Boolean,
      default: false,
    },

    search: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Array,
      default: () => [],
    },

    emptyMessage: String,

    border: {
      type: Boolean,
      default: true,
    },

    outline: {
      type: Boolean,
      default: true,
    },
  },

  data () {
    return {
      perPage: +this.$store.getters.info.defaultPerPage || 10,

      query: '',
      page: 1,

      data: this.getRows(),

      filter: {},
      filterDirty: false,

      sort: {
        key: 'idx',
        direction: 'asc',
        column: undefined,
      },

      dateShortcuts: [
        {
          text: 'This month',
          onClick () {
            const date = new Date()

            return [
              new Date(date.getFullYear(), date.getMonth()),
              new Date(date.getFullYear(), date.getMonth() + 1, 0),
            ]
          },
        },
      ],
    }
  },

  computed: {
    pages () {
      return this.pagination ? chunk(this.data, this.perPage) : this.data
    },

    pageCount () {
      return size(this.pages)
    },

    total () {
      return this.data.length
    },

    items () {
      return this.pagination ? (this.pages[this.page - 1] || this.pages[0] || []) : this.data
    },

    from () {
      return this.page * this.perPage - this.perPage + 1
    },

    to () {
      const val = this.page * this.perPage
      return val > this.total ? this.total : val
    },

    preparedFilters () {
      return this.filters.map(filter => {
        const options = new Map()

        this.rows.forEach(row => {
          const option = {
            label: '',
            key: '',
          }

          for (const [key] of Object.entries(option)) {
            if (typeof filter[key] === 'function') {
              option[key] = filter[key](row)
            } else if (has(row, filter[key])) {
              option[key] = get(row, filter[key])
            }
          }

          if (option.label && option.key) {
            options.set(option.label, option.key)
          }
        })

        if (options.size) {
          filter.options = Array.from(options, ([name, value]) => ({ name, value }))
          filter.options.sort((a, b) => a.name.localeCompare(b.name))
        }

        return filter
      })
    },
  },

  watch: {
    rows (value) {
      this.data = this.getRows(value)
      if (this.pagination) {
        this.page = 1
      }
    },

    filter: {
      handler () {
        this.update()
      },
      deep: true,
    },
  },

  methods: {
    changePageHandler (page = this.page) {
      this.page = page
    },

    changePerPageHandler (value) {
      if (this.page > this.pageCount) {
        this.page = 1
      }
      this.perPage = value
    },

    update () {
      this.filterDirty = some(this.filter) || this.query

      let result = this.getRows()
      if (this.search.length && this.query) {
        result = result.filter(row => this.search.some(field => row[field] && typeof row[field]?.toLowerCase === 'function' ? row[field].toLowerCase().includes(this.query.toLowerCase()) : false))
      }

      for (const [key, value] of Object.entries(this.filter)) {
        const filterDetails = this.filters.find(filter => filter.key === key)

        if (value) {
          switch (filterDetails.type) {
            case 'date':
              if (value[0] && value[1]) {
                result = result.filter(row => row[key] >= value[0] && row[key] <= value[1])
              }
              break
            default:
              result = result.filter(row => row[key] === value)
          }
        }
      }

      if (this.sort.column?.sortable) {
        const sort = this.getSortFunction()

        if (this.sort.direction === 'asc') {
          result.sort((a, b) => sort(a, b))
        } else if (this.sort.direction === 'desc') {
          result.sort((a, b) => sort(b, a))
        }
      }

      this.data = result

      if (this.pagination) {
        this.page = 1
      }
    },

    clearFilterHandler () {
      this.filter = {}

      if (this.search.length) {
        this.query = ''
      }
    },

    getRows (rows = this.rows) {
      return rows.map((row, index) => ({
        ...row,
        index,
        idx: index + 1,
      }))
    },

    doSort (event, column) {
      if (column.sortable && this.data.length) {
        this.sort.direction = column.key !== this.sort.key ? 'asc' : (this.sort.direction !== 'desc' ? 'desc' : 'asc')
        this.sort.column = column
        this.sort.key = column.key
        this.update()
      }
    },

    getSortFunction () {
      if (typeof this.sort.column.sort === 'function') {
        return this.sort.column.sort
      }

      return (a, b) => {
        const cellA = a[this.sort.key]
        const cellB = b[this.sort.key]

        if (cellA > cellB || (typeof cellA === 'undefined' && typeof cellB !== 'undefined')) {
          return 1
        } else if (cellA < cellB || (typeof cellB === 'undefined' && typeof cellA !== 'undefined')) {
          return -1
        }

        return 0
      }
    },
  },

  perPages,
}
</script>

<style lang="scss" scoped>
  .sortable {
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-right: 20px;

    &:hover {
      background-color: var(--tblr-gray-200);

      .theme-dark & {
        background-color: var(--tblr-gray-700);
      }
    }

    &-sort {
      background-color: transparent;
      background-position-x: center;
      background-repeat: no-repeat;
      background-size: contain;
      border: none;
      width: 12px;
      height: 16px;
      margin: 0;
      outline: 0;
      padding: 0;
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);

      &-neutral {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDEuOTk4IiBoZWlnaHQ9IjQwMS45OTgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwMS45OTggNDAxLjk5OCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTczLjA5MiAxNjQuNDUyaDI1NS44MTNjNC45NDkgMCA5LjIzMy0xLjgwNyAxMi44NDgtNS40MjQgMy42MTMtMy42MTYgNS40MjctNy44OTggNS40MjctMTIuODQ3cy0xLjgxMy05LjIyOS01LjQyNy0xMi44NUwyMTMuODQ2IDUuNDI0QzIxMC4yMzIgMS44MTIgMjA1Ljk1MSAwIDIwMC45OTkgMHMtOS4yMzMgMS44MTItMTIuODUgNS40MjRMNjAuMjQyIDEzMy4zMzFjLTMuNjE3IDMuNjE3LTUuNDI0IDcuOTAxLTUuNDI0IDEyLjg1IDAgNC45NDggMS44MDcgOS4yMzEgNS40MjQgMTIuODQ3IDMuNjIxIDMuNjE3IDcuOTAyIDUuNDI0IDEyLjg1IDUuNDI0em0yNTUuODEzIDczLjA5N0g3My4wOTJjLTQuOTUyIDAtOS4yMzMgMS44MDgtMTIuODUgNS40MjEtMy42MTcgMy42MTctNS40MjQgNy44OTgtNS40MjQgMTIuODQ3czEuODA3IDkuMjMzIDUuNDI0IDEyLjg0OEwxODguMTQ5IDM5Ni41N2MzLjYyMSAzLjYxNyA3LjkwMiA1LjQyOCAxMi44NSA1LjQyOHM5LjIzMy0xLjgxMSAxMi44NDctNS40MjhsMTI3LjkwNy0xMjcuOTA2YzMuNjEzLTMuNjE0IDUuNDI3LTcuODk4IDUuNDI3LTEyLjg0OCAwLTQuOTQ4LTEuODEzLTkuMjI5LTUuNDI3LTEyLjg0Ny0zLjYxNC0zLjYxNi03Ljg5OS01LjQyLTEyLjg0OC01LjQyeiIvPjwvc3ZnPg==);
        background-position-y: center;
        opacity: .3;

        .theme-dark & {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MDEuOTk4IiBoZWlnaHQ9IjQwMS45OTgiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwMS45OTggNDAxLjk5ODtmaWxsOndoaXRlOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTczLjA5MiAxNjQuNDUyaDI1NS44MTNjNC45NDkgMCA5LjIzMy0xLjgwNyAxMi44NDgtNS40MjQgMy42MTMtMy42MTYgNS40MjctNy44OTggNS40MjctMTIuODQ3cy0xLjgxMy05LjIyOS01LjQyNy0xMi44NUwyMTMuODQ2IDUuNDI0QzIxMC4yMzIgMS44MTIgMjA1Ljk1MSAwIDIwMC45OTkgMHMtOS4yMzMgMS44MTItMTIuODUgNS40MjRMNjAuMjQyIDEzMy4zMzFjLTMuNjE3IDMuNjE3LTUuNDI0IDcuOTAxLTUuNDI0IDEyLjg1IDAgNC45NDggMS44MDcgOS4yMzEgNS40MjQgMTIuODQ3IDMuNjIxIDMuNjE3IDcuOTAyIDUuNDI0IDEyLjg1IDUuNDI0em0yNTUuODEzIDczLjA5N0g3My4wOTJjLTQuOTUyIDAtOS4yMzMgMS44MDgtMTIuODUgNS40MjEtMy42MTcgMy42MTctNS40MjQgNy44OTgtNS40MjQgMTIuODQ3czEuODA3IDkuMjMzIDUuNDI0IDEyLjg0OEwxODguMTQ5IDM5Ni41N2MzLjYyMSAzLjYxNyA3LjkwMiA1LjQyOCAxMi44NSA1LjQyOHM5LjIzMy0xLjgxMSAxMi44NDctNS40MjhsMTI3LjkwNy0xMjcuOTA2YzMuNjEzLTMuNjE0IDUuNDI3LTcuODk4IDUuNDI3LTEyLjg0OCAwLTQuOTQ4LTEuODEzLTkuMjI5LTUuNDI3LTEyLjg0Ny0zLjYxNC0zLjYxNi03Ljg5OS01LjQyLTEyLjg0OC01LjQyeiIvPjwvc3ZnPg==);
        }
      }

      &-asc,
      &-desc {
        background-size: 10px;
      }

      &-asc {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgMTk3LjI4NyAxNTkuMDI4IDY5LjM4MWMtMy42MTMtMy42MTctNy44OTUtNS40MjQtMTIuODQ3LTUuNDI0cy05LjIzMyAxLjgwNy0xMi44NSA1LjQyNEw1LjQyNCAxOTcuMjg3QzEuODA3IDIwMC45MDQgMCAyMDUuMTg2IDAgMjEwLjEzNHMxLjgwNyA5LjIzMyA1LjQyNCAxMi44NDdjMy42MjEgMy42MTcgNy45MDIgNS40MjUgMTIuODUgNS40MjVoMjU1LjgxM2M0Ljk0OSAwIDkuMjMzLTEuODA4IDEyLjg0OC01LjQyNSAzLjYxMy0zLjYxMyA1LjQyNy03Ljg5OCA1LjQyNy0xMi44NDdzLTEuODE0LTkuMjMtNS40MjctMTIuODQ3eiIvPjwvc3ZnPg==);
        background-position-y: 35%;

        .theme-dark & {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MTtmaWxsOndoaXRlOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgMTk3LjI4NyAxNTkuMDI4IDY5LjM4MWMtMy42MTMtMy42MTctNy44OTUtNS40MjQtMTIuODQ3LTUuNDI0cy05LjIzMyAxLjgwNy0xMi44NSA1LjQyNEw1LjQyNCAxOTcuMjg3QzEuODA3IDIwMC45MDQgMCAyMDUuMTg2IDAgMjEwLjEzNHMxLjgwNyA5LjIzMyA1LjQyNCAxMi44NDdjMy42MjEgMy42MTcgNy45MDIgNS40MjUgMTIuODUgNS40MjVoMjU1LjgxM2M0Ljk0OSAwIDkuMjMzLTEuODA4IDEyLjg0OC01LjQyNSAzLjYxMy0zLjYxMyA1LjQyNy03Ljg5OCA1LjQyNy0xMi44NDdzLTEuODE0LTkuMjMtNS40MjctMTIuODQ3eiIvPjwvc3ZnPg==);
        }
      }

      &-desc {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MiIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiAwLTkuMjMzIDEuODA3LTEyLjg1IDUuNDI0QzEuODA3IDcyLjk5OCAwIDc3LjI3OSAwIDgyLjIyOGMwIDQuOTQ4IDEuODA3IDkuMjI5IDUuNDI0IDEyLjg0N2wxMjcuOTA3IDEyNy45MDdjMy42MjEgMy42MTcgNy45MDIgNS40MjggMTIuODUgNS40MjhzOS4yMzMtMS44MTEgMTIuODQ3LTUuNDI4TDI4Ni45MzUgOTUuMDc0YzMuNjEzLTMuNjE3IDUuNDI3LTcuODk4IDUuNDI3LTEyLjg0NyAwLTQuOTQ4LTEuODE0LTkuMjI5LTUuNDI3LTEyLjg1eiIvPjwvc3ZnPg==);
        background-position-y: 65%;

        .theme-dark & {
          background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOTIuMzYyIiBoZWlnaHQ9IjI5Mi4zNjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI5Mi4zNjIgMjkyLjM2MjtmaWxsOndoaXRlOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTI4Ni45MzUgNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiAwLTkuMjMzIDEuODA3LTEyLjg1IDUuNDI0QzEuODA3IDcyLjk5OCAwIDc3LjI3OSAwIDgyLjIyOGMwIDQuOTQ4IDEuODA3IDkuMjI5IDUuNDI0IDEyLjg0N2wxMjcuOTA3IDEyNy45MDdjMy42MjEgMy42MTcgNy45MDIgNS40MjggMTIuODUgNS40MjhzOS4yMzMtMS44MTEgMTIuODQ3LTUuNDI4TDI4Ni45MzUgOTUuMDc0YzMuNjEzLTMuNjE3IDUuNDI3LTcuODk4IDUuNDI3LTEyLjg0NyAwLTQuOTQ4LTEuODE0LTkuMjI5LTUuNDI3LTEyLjg1eiIvPjwvc3ZnPg==);
        }
      }
    }
  }
</style>
