<template>
  <component
    :is="tag"
    v-on="$listeners"
    v-bind="$attrs"
    :class="classes"
    :disabled="isDisabled"
    :aria-label="variant === 'close' ? $t('action.close') : false"
  >
    <slot />
  </component>
</template>

<script>
import FormMixin from '@/mixins/FormMixin'

import { allColors } from '@/helpers'

export default {
  name: 'AtomButton',

  mixins: [FormMixin],

  props: {
    color: {
      type: String,
      validator: value => allColors.includes(value),
    },

    variant: {
      type: String,
      validator: value => ['close', 'outline', 'ghost', 'square', 'pill', 'link'].includes(value),
    },

    size: {
      type: String,
      validator: value => ['lg', 'sm'].includes(value),
    },
  },

  computed: {
    classes () {
      if (this.variant === 'close') {
        return 'btn-close'
      }

      const output = {
        btn: true,
        'btn-loading': this.isLoading,
        'btn-disabled': this.isDisabled,

        'btn-lg': this.size === 'lg',
        'btn-sm': this.size === 'sm',
      }

      const classes = ['btn']

      switch (this.variant) {
        case 'outline':
          classes.push('outline')
          break
        case 'ghost':
          classes.push('ghost')
          break
        case 'square':
          classes.push('square')
          break
        case 'pill':
          classes.push('pill')
          break
        case 'link':
          classes.push('link')
          break
      }

      if (this.color && ['outline', 'ghost'].includes(this.variant)) {
        classes.push(this.color)
      }

      if (classes.length) {
        output[classes.join('-')] = true

        if (!['outline', 'ghost', 'link'].includes(this.variant) && this.color) {
          output[`btn-${this.color}`] = true
        }
      }

      if (this.isIconBtn) {
        output['btn-icon'] = true
      }

      return output
    },

    tag () {
      return this.$attrs.href ? 'a' : this.$attrs.to ? 'router-link' : 'button'
    },

    isIconBtn () {
      return this.$slots?.default?.length === 1 && this.$slots?.default[0].tag === 'svg'
    },
  },
}
</script>
