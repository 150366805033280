import { differenceInSeconds } from 'date-fns'

export default {
  data: () => ({
    blurred: new Date(),
  }),

  created () {
    window.onblur = () => { this.blurred = new Date() }
    window.onfocus = () => { if (differenceInSeconds(new Date(), this.blurred) > 180) window.location.reload() }
  },
}
