import { omit } from 'lodash'
import { firebase, notificationsCollection, companiesCollection, usersCollection } from '@/firebase'
import { getFullname, CodeError } from '@/helpers'
import Vue from 'vue'

export default {
  state: {
    notifications: [],
  },

  mutations: {
    setNotifications (state, notifications = []) {
      state.notifications = notifications
    },

    setNotification (state, notification) {
      const index = state.notifications.findIndex(c => c.id === notification.id)
      if (index !== -1) {
        Vue.set(state.notifications, index, notification)
      } else {
        state.notifications.unshift(notification)
      }
    },

    clearNotifications (state) {
      state.notifications = []
    },
  },

  actions: {
    async createNotification ({ commit, dispatch }, { message, type = 'info', userId, companyId }) {
      try {
        const uid = await dispatch('getUid')
        const data = {
          message,
          type,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          createdBy: uid,
          createdUser: usersCollection.doc(uid),
        }

        const output = {}

        if (companyId) {
          const company = await companiesCollection.doc(companyId).get()

          if (company.exists) {
            data.companyId = companyId
            data.company = company.ref
            output.company = omit(company.data(), ['createdUser', 'createdBy'])
          } else {
            throw new CodeError('company/not-found', `Company with ID: ${companyId} doesn't exist`)
          }
        } else if (userId) {
          const user = await usersCollection.doc(userId).get()

          if (user.exists) {
            data.userId = userId
            data.user = user.ref
            output.user = omit(user.data(), ['company', 'companyId'])
            output.user.fullname = getFullname(output.user)
          } else {
            throw new CodeError('user/not-found', `User with ID: ${userId} doesn't exist`)
          }
        }

        const notification = await notificationsCollection.add(data)
        const notificationData = (await notification.get()).data()
        commit('setNotification', { ...data, ...output, id: notification.id, createdAt: notificationData.createdAt })
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async fetchNotifications ({ commit, dispatch, getters }) {
      try {
        let notifications = []
        if (getters.isStaff) {
          notifications = (await notificationsCollection.get()).docs.map(doc => ({ ...doc.data(), id: doc.id }))
        } else {
          const uid = await dispatch('getUid')
          const info = getters.info
          const selfNotifications = await notificationsCollection.where('userId', '==', uid).get()
          notifications = selfNotifications.docs.map(doc => ({ ...doc.data(), id: doc.id }))
          if (info.companyId) {
            const companyNotifications = (await notificationsCollection.where('companyId', '==', info.companyId).get()).docs.map(doc => ({ ...doc.data(), id: doc.id }))
            notifications = [...notifications, ...companyNotifications]
          }
        }

        const output = []
        for (const notification of notifications) {
          const data = omit(notification, ['createdBy'])

          if (data.company && data.companyId) {
            const company = await data.company.get()
            if (!company.exists) {
              return
            } else {
              data.company = omit(company.data(), ['createdUser', 'createdBy'])
            }
          }

          if (getters.isStaff && data.user && data.userId) {
            const user = await data.user.get()
            if (!user.exists) {
              return
            } else {
              data.user = omit(user.data(), ['company', 'companyId'])
              data.user.fullname = getFullname(data.user)
            }
          }
          output.push(data)
        }

        output.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate())
        commit('setNotifications', output)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },
  },

  getters: {
    notifications: s => s.notifications,
    notificationsAmount: s => s.notifications.length,
  },
}
