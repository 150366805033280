<template>
  <div class="card-body">
    <!-- <div
      v-if="loading"
      class="progress progress-sm card-progress"
    >
      <div
        class="progress-bar"
        role="progressbar"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: `${progress}%` }"
        :aria-valuenow="progress"
      >
        <span class="visually-hidden">{{ progress + '%' }}</span>
      </div>
    </div> -->
    <MoleculeAvatarDetails
      class="mb-3"
      :title="info.fullname"
      :subtitle="info.position"
    />
    <div
      v-if="info.company && info.company.name && info.company.inn"
      class="text-muted mb-3"
    >
      <div>
        <building-icon /> {{ info.company.name }}
      </div>
      <div>({{ info.company.inn }})</div>
    </div>
    <div class="list-group list-group-transparent">
      <template v-for="link in filteredLinks">
        <router-link
          :to="link.url"
          :key="link.url"
          exact-active-class="active"
          class="list-group-item list-group-item-action d-flex align-items-center"
          v-text="link.title"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MoleculeAvatarDetails from '@/components/MoleculeAvatarDetails'

export default {
  name: 'OrganismUserCard',

  components: {
    MoleculeAvatarDetails,
  },

  data: () => ({
    loading: false,
  }),

  computed: {
    ...mapGetters(['info', 'isAdmin', 'isSuperManager', 'isManager', 'isStaff']),

    links () {
      return [
        {
          title: this.$t('pages.users.title'),
          url: '/users',
          superManager: true,
        },
        {
          title: this.$t('pages.profile.title'),
          url: '/profile',
        },
        {
          title: this.$t('pages.profile.edit.title'),
          url: '/profile/edit',
        },
        {
          title: this.$t('pages.password.change.title'),
          url: '/password/change',
        },
        {
          title: this.$t('pages.notifications.title'),
          url: '/notifications',
        },
        {
          title: this.$t('pages.companies.title'),
          url: '/profile/companies',
          manager: true,
        },
      ]
    },

    filteredLinks () {
      let filter

      if (this.isSuperManager) {
        filter = link => !link.admin
      } else if (this.isManager) {
        filter = link => !link.admin && !link.superManager
      } else {
        filter = link => !link.admin && !link.superManager && !link.manager
      }

      return this.isAdmin ? this.links : this.links.filter(filter)
    },
  },

  // methods: {
  //   ...mapActions(['getAvatarReference', 'updateInfo', 'removeAvatar']),
  //
  //   async uploadAvatar (cropper) {
  //     try {
  //       cropper.getCroppedCanvas().toBlob(async blob => {
  //         this.loading = true
  //         const ref = await this.getAvatarReference()
  //         const task = ref.put(blob)
  //         task.on('state_changed', {
  //           next: snapshot => {
  //             const percent = snapshot.bytesTransferred / snapshot.totalBytes * 100
  //             this.progress = percent
  //           },
  //           complete: async () => {
  //             const photoUrl = await ref.getDownloadURL()
  //             await this.updateInfo({ photoUrl })
  //             this.progress = 0
  //             this.loading = false
  //           }
  //         })
  //       })
  //     } catch (e) {}
  //   }
  // },
}
</script>
