<template>
  <div id="app">
    <component :is="template">
      <router-view />
    </component>
    <notifications
      :ignoreDuplicates="true"
      :duration="5000"
      position="top center"
    >
      <template
        slot="body"
        slot-scope="props"
      >
        <MoleculeAlert
          :title="props.item.title"
          :color="props.item.type"
          :icon="getNotificationIcon(props.item.type)"
          @close="props.close"
        >
          <span v-html="props.item.text" />
        </MoleculeAlert>
      </template>
    </notifications>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TemplateEmpty from '@/components/TemplateEmpty'
import TemplateMain from '@/components/TemplateMain'
import TemplateProfile from '@/components/TemplateProfile'
import MoleculeAlert from '@/components/MoleculeAlert'
import AtomButton from '@/components/AtomButton'

import UpdateMixin from '@/mixins/UpdateMixin'
import ThemeMixin from '@/mixins/ThemeMixin'

export default {
  name: 'App',

  components: {
    TemplateEmpty,
    TemplateMain,
    TemplateProfile,
    MoleculeAlert,
    AtomButton,
  },

  mixins: [UpdateMixin, ThemeMixin],

  computed: {
    ...mapGetters(['error']),

    template () {
      return 'Template' + this.$options.filters.capitalize(this.$route.meta.layout || 'empty')
    },
  },

  watch: {
    error (error) {
      this.$notify({
        title: this.$t('action.error'),
        text: this.$te('error.' + error.code) ? this.$t('error.' + error.code) : error.message || this.$t('error.unknown'),
        type: 'danger',
      })
    },
  },

  methods: {
    getNotificationIcon (type) {
      if (!type) return null

      switch (type) {
        case 'success':
          return 'check'
        case 'info':
          return 'info-circle'
        case 'warning':
          return 'alert-triangle'
        case 'danger':
          return 'alert-circle'
        default:
          return null
      }
    },
  },
}
</script>
